.perks-table-container {
  display: flex;
  flex-direction: column;
  padding: var(--content-padding);
  margin-bottom: 30px;

  .perks-table-title {
    flex-shrink: 0;
    height: calc(2 * var(--content-padding));
    display: flex;
    font-size: 28px;
  }
  .ellipsis-cell {
    min-width: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .save-cell {
    color: var(--Green);
  }

  .cancel-cell {
    color: var(--Red);
  }

  .delete-cell {
    color: var(--Red);
    opacity: 0;
  }

  .edit-cell {
    color: var(--NickelPrimaryLight);
    opacity: 0;
  }

  .ka {
    --table-height: calc(var(--container-height) - 2 * var(--content-padding));
    --header-row-height: 48px;

    height: var(--table-height);

    .ka-tbody {
      max-height: calc(var(--table-height) - var(--header-row-height));
    }

    .ka-row {
      height: 60px;
      &:hover {
        .delete-cell {
          opacity: 1;
        }
        .edit-cell {
          opacity: 1;
        }
      }
    }
  }
}
