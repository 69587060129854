.blob {
  position: absolute;
  z-index: 0;
  fill: #fff;

  &.top-left {
    top: 0;
    left: 0;
    transform: scale(-1, 1);
  }
  &.bottom-right {
    bottom: 0;
    right: 0;
    transform: scale(-1, -1);
  }
}
