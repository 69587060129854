.header {
  z-index: 22;
  flex-shrink: 0;
  height: var(--header-height);
  width: 100%;
  padding: 0px 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--primary-color);

  .search {
    height: 44px;
    min-width: 50%;
    margin-left: 48px;
    padding-left: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    border-radius: 26.5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);

    .search-icon {
      color: rgba(0, 0, 0, 0.3);
      margin-right: 8px;
    }

    .search-input {
      height: 32px;
      width: calc(100% - 48px - 18px - 18px);
      font-size: 16px;
      border: none;
      outline: none;

      &::placeholder {
        color: rgba(0, 0, 0, 0.3);
      }
    }
  }

  .header-buttons {
    height: 44px;
    margin-left: auto; // to push it to the right end
    display: flex;

    .header-button {
      height: 44px;
      width: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 22px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      overflow: hidden;

      > a {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & + .header-button,
      & + .profile-picture-button {
        margin-left: 16px;
      }

      .header-button-icon {
        color: var(--primary-color);
      }

      &.profile img {
        height: 100%;
      }
    }
  }
}
