.users-table-container {
  --container-height: calc(var(--content-inner-height) - 2 * var(--content-padding));

  height: var(--container-height);
  display: flex;
  flex-direction: column;

  .users-table-title {
    flex-shrink: 0;
    height: calc(2 * var(--content-padding));
    display: flex;
    font-size: 28px;
  }

  .ka {
    --table-height: calc(var(--container-height) - 2 * var(--content-padding));
    --header-row-height: 48px;

    height: var(--table-height);

    .ka-tbody {
      max-height: calc(var(--table-height) - var(--header-row-height));

      > .ka-tr:nth-child(even) {
        .profile-picture,
        .profile-picture-placeholder {
          background-color: var(--Gray300);
        }
      }
    }
  }

  .user-cell {
    height: 60px;
    width: 428px;
    display: flex;
    align-items: center;

    .profile-picture,
    .profile-picture-placeholder {
      height: 44px;
      width: 44px;
      margin-right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 22px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      overflow: hidden;

      img {
        object-fit: cover;
      }

      .profile-placeholder-icon {
        stroke: var(--Gray500);
      }
    }

    .user-name {
      width: 144px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .user-pending {
      width: 64px;
      margin-left: 16px;
      padding: 0px 8px;
      box-sizing: border-box;
      border-radius: 10px;
      background-color: var(--secondary-color);
      color: var(--White);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .user-resend {
      width: 128px;
      margin-left: 16px;
      font-size: 14px;
      font-variant: small-caps;
      color: var(--NickelPrimaryLight);
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .ellipsis-cell {
    min-width: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .delete-cell {
    color: var(--Gray400);
  }
}
