.ledger-table-container {
  --container-height: calc(
    var(--content-inner-height) - 2 * var(--content-padding)
  );

  height: var(--container-height);
  display: flex;
  flex-direction: column;

  .ledger-table-title {
    flex-shrink: 0;
    height: calc(2 * var(--content-padding));
    display: flex;
    font-size: 28px;
  }

  .ka {
    --table-height: calc(var(--container-height) - 2 * var(--content-padding));
    --header-row-height: 48px;

    height: var(--table-height);

    .ka-table {
      .ka-tbody {
        max-height: calc(var(--table-height) - var(--header-row-height));
      }

      .ka-row {
        cursor: initial;
      }
    }

    .details-cell {
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      .campaign-link,
      .transactions-link {
        color: var(--Blue);
      }
    }
  }
}
