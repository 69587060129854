.dropdown-button {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & + button,
  & + .dropdown-button {
    margin-left: 16px;
  }

  .select-menu {
    position: absolute;
    top: 48px;
    right: 0px;
    width: max-content;
    min-width: 250px;
    max-height: 320px;
    padding: 0px 32px 0px 0px;
    border-radius: 8px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    overflow-y: scroll;
    z-index: 2;

    .menu-option {
      position: relative;
      height: 24px;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .selected-icon {
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translate(0, -50%);
        color: var(--primary-color);
        stroke: var(--primary-color);
        // fill: var(--primaryColor);
      }
    }
  }
}
