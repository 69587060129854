.payouts-table-container {
  --container-height: calc(var(--content-inner-height) - 2 * var(--content-padding));

  height: var(--container-height);
  display: flex;
  flex-direction: column;

  .ka {
    --header-row-height: 48px;

    .ka-tbody {
      max-height: calc(var(--container-height) - var(--header-row-height));
    }
  }

  .reconciled-cell {
    width: 100%;
    display: flex;
    // justify-content: center;
    align-items: center;

    .reconcile-icon {
      &:hover {
        opacity: 0.7;
      }
      &:active {
        opacity: 0.5;
      }
    }

    .reconciled-info {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .reconciled-timestamp {
        margin-left: 12px;
        color: var(--Gray500);
        font-size: 10px;
        line-height: 12px;
      }
    }
  }

  .total-cell {
    font-weight: 600;
    letter-spacing: 0.5px;
  }

  .negative {
    color: var(--Red);
  }
}
