.datepicker-component-container {
  width: 100%;
  position: relative;

  .datepicker-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .datepicker-input-box {
      position: relative;
      width: 100%;
      min-width: 120px;
      cursor: pointer;
    }

    .datepicker-menu {
      position: absolute;
      top: calc(100% + 4px);
      left: 0;
      width: 100%;
      min-width: 120px;
      padding: 8px 16px;
      border-radius: 8px;
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
      border: solid 1px var(--Gray400);
      background-color: var(--Gray100);
      overflow-y: scroll;
      user-select: none;
      z-index: 1;

      &.closed {
        visibility: hidden;
      }

      .menu-header {
        width: 100%;
        max-height: 100px;
        padding: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;

        > * {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .menu-title {
          cursor: none;
        }
      }

      .calendar {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: repeat(auto-fill, 38px);

        .calendar-day {
          position: relative;
          min-width: 12px;
          min-height: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: var(--Gray900);
          cursor: pointer;

          &.unselectable {
            pointer-events: none;
            color: var(--Gray300);
          }

          &.selectable {
            cursor: pointer;
          }

          &.selected {
            position: relative;

            &::before {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              height: 28px;
              width: 28px;
              background-color: var(--primary-color);
              border-radius: 50%;
            }
          }

          .date {
            padding: 4px;

            &.today {
              color: var(--secondary-color);
            }
          }

          &.selected {
            .date {
              color: var(--White);
              z-index: 1;
            }
          }
        }
      }
    }
    select {
      width: 100%;
      height: 40px;
      padding: 8px 0px 8px 16px;
      border-radius: 8px;
      box-sizing: border-box;
      border: solid 1px var(--Gray400);
      background-color: var(--White);
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: var(--Gray900);

      &::placeholder {
        color: #222;
        opacity: 0.2;
        font-family: Montserrat;
      }
      &:focus {
        outline: none;
      }
    }

    .icon-container {
      width: 40px;
      height: 40px;
      position: absolute;
      right: 0;
      background-color: var(--Gray200);
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border: solid 1px var(--Gray400);
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;

      > * {
        opacity: 0.3;
        color: var(--Black);
      }
    }
  }
}
