html {
  --NickelPrimary: #2487ff;
  --NickelPrimaryDark: #2d6cda;
  --NickelPrimaryLight: #57a2ff;
  --White: #ffffff;
  --Black: #000000;
  --Red: #d24f37;
  --Yellow: #daa942;
  --Green: #2db486;
  --Purple: #6f5ed3;
  --Blue: #3896e3;
  --Orange: #fa8944;
  --Pink: #db61db;
  --brownish-grey-three: #777777;
  --Gray100: #f5f5f5;
  --Gray200: #eeeeee;
  --Gray300: #e0e0e0;
  --Gray50: #fafafa;
  --Gray400: #bdbdbd;
  --Gray600: #757575;
  --Gray700: #616161;
  --Gray800: #424242;
  --Gray500: #9e9e9e;
  --Gray900: #212121;
}
