.sidebar {
  z-index: 10;
  flex-shrink: 0;
  height: calc(100vh - var(--header-height));
  width: var(--sidebar-width);
  box-sizing: border-box;
  background-color: var(--White);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);

  .content {
    height: 100%;
    width: 100%;
    padding: 20px 22px 8px;
    box-sizing: border-box;
    overflow-y: auto;

    .back-button {
      display: flex;
      align-items: center;
      margin-left: -4px;
      margin-bottom: 24px;
      color: var(--primary-color);
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      cursor: pointer;
      user-select: none;

      &:active {
        opacity: 0.7;
      }
    }

    .section {
      margin-bottom: 20px;
      border-bottom: 1px solid var(--Gray400);

      &:last-of-type {
        border-bottom: none;
      }

      .section-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: var(--Gray500);
      }

      .section-links {
        margin-top: 10px;
        margin-bottom: 14px;
        user-select: none;

        .section-link {
          height: 24px;
          margin-left: -24px;
          padding: 3px 0;
          padding-left: 24px;
          display: block;
          color: --Gray900;
          font-size: 15px;
          line-height: 24px;
          border-top-right-radius: 100px;
          border-bottom-right-radius: 100px;
          cursor: pointer;

          &:active {
            opacity: 0.7;
          }

          &.selected {
            color: #fff;
            background-color: var(--secondary-color);

            &:active {
              opacity: 1;
            }
          }

          + .section-link {
            margin-top: 5px;
          }
        }
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
