.textarea-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .textarea-label {
    min-width: 130px;
    height: 27px;
    margin-bottom: 8px;
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--Gray900);
  }

  textarea {
    width: 100%;
    padding: 8px 16px 16px 16px;
    border-radius: 8px;
    box-sizing: border-box;
    border: solid 1px var(--Gray400);
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--Gray900);
    resize: none;
    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #222;
      opacity: 0.2;
      font-family: Montserrat;
    }
  }

  .character-counter-container {
    position: absolute;
    bottom: -16px;
    right: 16px;
    padding-top: 2px;
  }
}
