.edit-reward-option-card {
  position: relative;
  height: 350px;
  width: 100%;
  margin-bottom: var(--content-padding);
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
  cursor: grab;
  user-select: none;

  .sorting-overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    color: var(--Gray700);
    font-size: 96px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 12px;
    overflow: hidden;
  }

  &.sorting {
    .sorting-overlay {
      display: flex;
      pointer-events: none;
    }
  }

  .reward-option-slides-container {
    height: 100%;
    width: 100%;
    overflow: hidden;

    > .reward-option-slide {
      height: 100%;
      width: 100%;
      padding: 12px 16px;
      box-sizing: border-box;
    }

    .top-bar {
      width: 100%;
      margin: 8px 0px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .title {
      color: var(--Gray900);
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 12px;
    }

    .choices-container {
      width: 100%;
      margin-top: 32px;
      margin-bottom: 16px;

      .value-line {
        width: 100%;
        margin-bottom: 12px;
        padding-left: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: var(--Gray600);
        font-size: 18px;
        font-weight: 500;
      }

      .more-label {
        width: 100%;
        text-align: right;
        color: var(--Gray400);
        font-size: 18px;
        font-weight: 500;
      }
    }

    .reward-edit-toggle {
      color: var(--NickelPrimaryLight);
      font-size: 12px;
      cursor: pointer;
    }
  }

  textarea {
    min-height: 150px;
  }

  .delete-reward-option-button {
    position: absolute;
    z-index: 2;
    top: -16px;
    right: -16px;
    height: 32px;
    width: 32px;
    padding: 6px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: var(--Gray700);
    background-color: var(--White);
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
    display: none;
    cursor: pointer;

    > :hover {
      opacity: 0.7;
    }

    > :active {
      opacity: 0.3;
    }
  }

  &:hover,
  &:active {
    .delete-reward-option-button {
      display: flex;
    }
  }

  &.reward-option-drag-ghost {
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.32);
    pointer-events: auto !important;
    cursor: grabbing;

    .sorting-overlay {
      display: none;
    }
  }
}
