// Inherits variables from ../Statistics.scss
.donations-received-gauge {
  --Yellow: #fbcd1d;
  --Green: #29c4b2;

  position: relative;
  width: 100%;
  height: var(--right-col-width);
  margin-bottom: var(--dashboard-gap);
  padding: calc(var(--dashboard-gap) * 2 / 3) var(--dashboard-gap);
  box-sizing: border-box;
  background-color: var(--White);
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-box-shadow);

  .chart-title {
    --font-size-base: 16;
    --font-size-pct: calc(var(--font-size-base) / var(--right-col-pixel-base));
    --font-size: calc(var(--right-col-width) * var(--font-size-pct));
    font-size: var(--font-size);

    --line-height-base: 24;
    --line-height-pct: calc(
      var(--line-height-base) / var(--right-col-pixel-base)
    );
    --line-height: calc(var(--right-col-width) * var(--line-height-pct));
    line-height: var(--line-height);
  }

  .chart-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 4 * var(--dashboard-gap));

    svg {
      --gauge-height-base: 160;
      --gauge-width-base: 210;

      width: 100%;
      padding-top: calc(
        1% * var(--gauge-height-base) / var(--gauge-width-base)
      );
    }
  }

  .info-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: calc(80% - 4 * var(--dashboard-gap));

    --margin-top-base: 40;
    --margin-top-pct: calc(
      var(--margin-top-base) / var(--right-col-pixel-base)
    );
    --margin-top: calc(var(--right-col-width) * var(--margin-top-pct));
    margin-top: var(--margin-top);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .icon-wrapper {
      --wrapper-pixel-base: 40;
      --wrapper-pct: calc(
        var(--wrapper-pixel-base) / var(--right-col-pixel-base)
      );
      --wrapper-size: calc(var(--right-col-width) * var(--wrapper-pct));
      height: var(--wrapper-size);
      width: var(--wrapper-size);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: calc(var(--wrapper-size) / 2);

      .summary-icon {
        --pixel-base: 20;
        --pct: calc(var(--pixel-base) / var(--right-col-pixel-base));
        --size: calc(var(--right-col-width) * var(--pct));
        height: var(--size);
        width: var(--size);
        color: #fff;
      }
    }

    .value {
      --margin-top-base: 20;
      --margin-top-pct: calc(
        var(--margin-top-base) / var(--right-col-pixel-base)
      );
      --margin-top: calc(var(--right-col-width) * var(--margin-top-pct));
      margin-top: var(--margin-top);

      --font-size-base: 66;
      --font-size-pct: calc(
        var(--font-size-base) / var(--right-col-pixel-base)
      );
      --font-size: calc(var(--right-col-width) * var(--font-size-pct));
      font-size: var(--font-size);

      font-weight: 500;

      --letter-spacing-base: -2;
      --letter-spacing-pct: calc(
        var(--letter-spacing-base) / var(--right-col-pixel-base)
      );
      --letter-spacing: calc(
        var(--right-col-width) * var(--letter-spacing-pct)
      );
      letter-spacing: var(--letter-spacing);
    }

    .title {
      max-width: 80%;

      --margin-top-base: 12;
      --margin-top-pct: calc(
        var(--margin-top-base) / var(--right-col-pixel-base)
      );
      --margin-top: calc(var(--right-col-width) * var(--margin-top-pct));
      margin-top: var(--margin-top);

      --font-size-base: 16;
      --font-size-pct: calc(
        var(--font-size-base) / var(--right-col-pixel-base)
      );
      --font-size: calc(var(--right-col-width) * var(--font-size-pct));
      font-size: var(--font-size);

      --line-height-base: 24;
      --line-height-pct: calc(
        var(--line-height-base) / var(--right-col-pixel-base)
      );
      --line-height: calc(var(--right-col-width) * var(--line-height-pct));
      line-height: var(--line-height);

      text-align: center;
    }
  }
}
