.contributions-table-container {
  --summary-numbers-height: 156px;
  --title-height: 48px;
  display: flex;
  flex-direction: column;

  .contributions-table-title {
    flex-shrink: 0;
    height: var(--title-height);
    padding: 0px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-text {
      font-size: 13px;
      font-weight: 500;
    }
  }

  .spinner-container {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ka {
    --header-row-height: 48px;
    box-shadow: none;
    border-radius: 0px;

    .ka-tbody {
      max-height: calc(var(--container-height) - var(--title-height) - var(--header-row-height));
    }

    .ka-thead-cell {
      padding: 15px 12px;
    }

    .ka-cell {
      padding: 8px 12px;
    }
  }

  .open-modal-button {
    border: 0;
    background: none;
    padding: 0;
    cursor: pointer;
  }
}
