.team-perks-modal {
  min-height: 420px;
  max-height: 90vh;
  width: 600px;
  display: flex;
  flex-direction: column;
  background-color: var(--White);
  overflow-y: auto;

  .modal-contents {
    flex: 1;
    flex-grow: 1;
    padding: 45px 32px 36px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .headline {
      width: 100%;
      margin-bottom: 24px;
      color: var(--Gray900);
      font-size: 32px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }

    .inputs {
      > * + * {
        margin-top: 16px;
      }
    }

    .details-container {
      width: 100%;
      margin-top: 48px;
    }
  }

  .bottom-buttons {
    padding: 14px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--Gray200);
  }

  .insert-button {
    border: none;
    background: none;
    margin-top: 5px;
    color: var(--primary-color);
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }

  .remove-button {
    border: none;
    background: none;
    cursor: pointer;
  }

  .array-field {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
}
