.officer-verification-slide {
  // gets positioning classes from .slide in NewCampaignModal.scss
  .explanation {
    margin-top: 12px;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: center;

    .persona-test-button {
      height: 40px;
      padding: 8px;
      border-radius: 8px;
      background-color: var(--NickelPrimary);

      &:active {
        opacity: 0.7;
      }
    }
  }
}
