.welcome-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 300;

  > * {
    animation: breathe 1.25s ease-in-out alternate infinite;
  }
}
