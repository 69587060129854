.details-container {
  padding-top: 60px;

  .inputs-container {
    width: 350px;
    margin: 0 150px 30px 0;

    &:last-child {
      margin-right: 0;
    }

    &--wide {
     width: 660px;
    }

    .nickel-auth-input {
      margin-bottom: 8px;

      .error-text {
        margin-top: 2px;
      }
    }
  }

  .inputs-shared-row {
    display: flex;

    .nickel-auth-input,
    .nickel-select {
      width: 200px;
      margin-right: 20px;
    }
  }

  .insert-button {
    border: none;
    background: none;
    margin-top: 5px;
    color: var(--primary-color);
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }

  .remove-button {
    border: none;
    background: none;
    cursor: pointer;
  }

  .array-field {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .field-group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 900px;
  }

  .shipping-address {
    padding-bottom: 160px;
  }
}
