.renewal-flow-container {
  height: var(--content-inner-height);
  width: 100%;
  padding: var(--content-padding) var(--content-padding) 0px;
  overflow: scroll;
  box-sizing: border-box;

  .days-out-body {
    padding-top: var(--content-padding);
    display: flex;
    justify-content: space-between;

    .days-out-col {
      flex-grow: 1;
      max-width: calc(50% - var(--content-padding));

      .days-out-col-title {
        font-size: 32px;
      }

      > :not(:first-child) {
        margin-top: var(--content-padding);
      }
    }
  }
}
