.personal-container {
  height: var(--content-inner-height);
  width: 100%;
  padding: var(--content-padding) var(--content-padding) 0px;
  overflow: scroll;
  box-sizing: border-box;

  .personal-inputs {
    display: flex;
    border-bottom: 1px solid var(--Gray400);

    .picture {
      width: 240px;
      margin: 0 60px 24px 0;
    }

    .name-container {
      display: flex;
      flex-direction: row;
      margin: 48px 0 16px;
      font-weight: bold;
      font-size: 36px;
    }
  }
}
