.media-slide {
  display: flex;
  flex-direction: column;

  .inputs :last-child {
    margin-bottom: 0px !important;
  }

  .upload-details {
    margin-top: 10px;
  }
}
