.details-slide {
  .double-input {
    width: 100%;
    display: flex;
    justify-content: space-between;

    > * {
      width: calc(50% - 8px);
    }
  }
  // gets positioning classes from .slide in NewCampaignModal.scss
}
