.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.95;
  }
  &:active {
    opacity: 0.8;
  }

  &.hidden {
    visibility: hidden;
  }

  & + button,
  & + .dropdown-button {
    margin-left: 16px;
  }

  &.light {
    background-color: var(--White);
    color: var(--primary-color);
    border: solid 1px var(--Gray300);

    &:hover {
      background-color: var(--Gray50);
    }
    &:active {
      opacity: 0.7;
    }
  }

  &.dark {
    background-color: var(--primary-color);
    color: var(--White);
  }

  &.secondary {
    background-color: var(--secondary-color);
    color: var(--White);
  }

  &.negative {
    background-color: var(--Red);
    color: var(--White);
  }

  &.static {
    opacity: 1;
    cursor: initial;

    &:active {
      opacity: 1;
    }
    &:hover {
      opacity: 1;
    }
  }

  &.disabled {
    cursor: no-drop;
    opacity: 0.5;

    &:active {
      opacity: 0.5;
    }
    &:hover {
      opacity: 0.5;

      &.light {
        background-color: var(--White);
      }
    }
  }

  .button-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button-text {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
  }

  .button-icon + .button-text {
    padding-left: 12px;
  }
}
