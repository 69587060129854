html {
  --header-height: 72px;

  --page-header-height: 64px;
  --page-header-spacing: 24px;
  --page-header-bottom-border-width: 1px;
  --page-header-total-height: calc(
    var(--page-header-height) + var(--page-header-spacing) + var(--page-header-bottom-border-width)
  );

  --sidebar-width: 220px;

  --content-padding: 24px;

  --content-wrapper-height: calc(100vh - var(--header-height));
  --content-inner-height: calc(
    var(--content-wrapper-height) - var(--page-header-total-height) - var(--content-padding)
  );

  --content-wrapper-width: calc(100vw - var(--sidebar-width));
  --content-inner-width: calc(var(--content-wrapper-width) - (2 * var(--content-padding)));
}
