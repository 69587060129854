.team-members-slide {
  // gets positioning classes from .slide in NewCampaignModal.scss

  display: flex;
  flex-direction: column;

  .double-input-container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    > * {
      width: calc(50% - 8px);
    }
  }

  .add-custom {
    font-size: 12px;
    color: var(--NickelPrimaryLight);
    cursor: pointer;
  }

  .custom-info-container {
    width: 100%;
    display: flex;

    // padding-bottom: 80px;

    .custom-info-title {
      width: 100%;
      margin-right: 4px;
    }
    .custom-info-value-container {
      width: 100%;
      margin-left: 4px;

      .custom-info-value {
        margin-bottom: 8px;
      }
      .add-value {
        margin-top: 12px;
        font-size: 12px;
        color: var(--NickelPrimaryLight);
        cursor: pointer;
      }
      .button-row {
        width: 100%;
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
        height: 40px;
        bottom: 0;
      }
    }
  }
}
