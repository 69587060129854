.schedule-steps-modal {
  height: 620px;
  width: 580px;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  .modal-contents {
    flex: 1;
    flex-grow: 1;
    padding: 45px 32px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    .headline {
      width: 100%;
      color: var(--Gray900);
      font-size: 32px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      margin-bottom: 64px;
    }

    .content-container {
      display: flex;
      flex-direction: column;

      > * {
        margin-bottom: 24px;
      }
      .double-input-container {
        width: 100%;
        display: flex;
        justify-content: space-between;

        > * {
          width: calc(50% - 8px);
        }
      }
    }
  }
  .bottom-buttons {
    padding: 14px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--Gray200);

    .right-buttons {
      display: flex;
    }
  }
}
