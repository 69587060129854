.clubhub-app-features {
  height: var(--content-inner-height);
  width: 100%;
  padding: var(--content-padding) var(--content-padding) 0px;
  box-sizing: border-box;
  overflow: scroll;

  .feature-row {
    max-width: 400px;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;

    .feature-label {
      font-size: 18px;
      font-weight: 500;
    }
  }
}
