.ka-table {
  .purpose-cell {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .campaign-link {
      color: var(--Blue);
    }
  }
}
