.bulletin-overview {
  height: var(--content-inner-height);
  width: 100%;
  padding: var(--content-padding) var(--content-padding) 0px;
  display: flex;
  justify-content: space-between;
  overflow: scroll;
  box-sizing: border-box;

  .bulletin-content-col {
    flex-grow: 1;
    max-width: calc(50% - var(--content-padding));
    padding-bottom: var(--content-padding);

    .bulletin-content-col-title {
      font-size: 32px;
    }

    > :not(:first-child) {
      margin-top: var(--content-padding);
    }
  }
}
