.main-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;

  .body-wrapper {
    flex: 1;
    display: flex;

    .body {
      flex: 1;
      height: 100%;

      > * {
        height: 100%;
        width: 100%;
      }
    }
  }
}

.loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  > * {
    animation: breathe 1.25s ease-in-out alternate infinite;
  }
}

.loading-overlay {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  > * {
    animation: breathe 1.25s ease-in-out alternate infinite;
  }
}

a {
  text-decoration: none;
  color: initial;
}

input,
textarea {
  font-family: Montserrat;
}

.Toastify {
  > * > * {
    position: relative;
    border-radius: 8px;
    padding: 8px 8px 8px 24px;
    font-family: Montserrat, sans-serif;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1),
      0 2px 15px 0 rgba(0, 0, 0, 0.05);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 8px;
      height: 100%;
      background-color: var(--primary-color);
      animation: breathe ease-out 1 forwards;
      animation-duration: 5000ms;
    }
  }

  .Toastify__progress-bar--default {
    background: none;
  }
}

@keyframes breathe {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.1;
  }
}
