.page-wrapper {
  max-width: var(--content-wrapper-width);
  height: var(--content-wrapper-height);
  box-sizing: border-box;
  background-color: var(--Gray50);
  overflow: hidden;

  &--full-width {
    max-width: unset;
    padding: 0 100px;
  }

  .content-wrapper {
    position: relative;
    min-height: var(--content-inner-height);
  }
}
