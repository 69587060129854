.contribution-details-modal {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--White);

  .modal-contents {
    max-height: 80%;
    padding: 45px 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .details-table {
      max-height: 100%;
    }
  }

  .headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    width: 100%;

    .title {
      color: var(--Gray900);
      font-size: 32px;
      font-weight: 500;
    }

    .button {
      font-size: 14px;
      padding: 12px 15px;
      font-weight: 600;
      border-radius: 8px;
      background-color: var(--primary-color);
      color: var(--White);
      border: solid 1px var(--Gray300);

      &:hover {
        opacity: 0.95;
      }
    }
  }
}
