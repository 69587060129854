.cta-slide {
  // gets positioning classes from .slide in NewCampaignModal.scss

  display: flex;
  flex-direction: column;

  .double-input-container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    > * {
      width: calc(50% - 8px);
    }
  }

  .button-container {
    .cancel-button {
      width: 98px;
      height: 40px;
      border-radius: 8px;
      border: solid 1px var(--Gray300);
      background-color: var(--White);
    }
  }
}
