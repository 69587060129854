.nickel-auth-input {
  position: relative;
  width: 100%;
  height: 40px;

  + .nickel-auth-input {
    margin-top: 22px;
  }

  input {
    height: 100%;
    width: 100%;
    padding: 0px 8px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 34px;
    border-radius: 3px;
    outline: none;
    border: none;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);

    &::placeholder {
      color: #222;
      opacity: 0.2;
      font-family: Montserrat;
    }
  }

  .alt {
    width: 100%;
    height: 40px;
    padding: 8px 0px 8px 16px;
    border-radius: 8px;
    box-sizing: border-box;
    border: solid 1px var(--Gray400);
    background-color: var(--White);
    box-shadow: none;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--Gray900);

    &::placeholder {
      color: #222;
      opacity: 0.2;
      font-family: Montserrat;
    }
    &:focus {
      outline: none;
    }
  }

  .nickel-auth-input-error {
    width: 100%;
    margin: 0px;
    margin-top: 4px;
    font-size: 12px;
    line-height: 18px;
    color: #E0614A;
  }

  .nickel-auth-input-error-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(0, -50%);
    height: 16px;
    width: 16px;
  }
}
