html {
  --primary-color: #888;
  --secondary-color: #ccc;

  // MINNESOTA
  // --primary-color: #872334;
  // --secondary-color: #fbba3b;

  // UTAH
  // --primary-color: #cc0000;
  // --secondary-color: #808080;
  // --secondary-color: #000;

  // VIRGINIA TECH
  // --primary-color: #630031;
  // --secondary-color: #cf4420;

  // BOISE STATE
  // --primary-color: #0033a0;
  // --secondary-color: #d64309;

  // WAKE
  // --primary-color: #000;
  // --secondary-color: #9e7e38;
}
