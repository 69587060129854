.confirm-remove-level-modal {
  height: 280px;
  width: 500px;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  .modal-contents {
    flex: 1;
    flex-grow: 1;
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .headline {
      width: 100%;
      color: var(--Gray900);
      font-size: 32px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }

    .input-subtext {
      margin-top: 2px;
      margin-left: 16px;
      color: var(--Gray500);
      font-size: 11px;
    }
  }

  .bottom-buttons {
    padding: 14px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--Gray200);

    .timer-container {
      height: 100%;
      width: 96px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
