.tab-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid var(--Gray300);

  .tab {
    position: relative;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    > * {
      transition: all 150ms linear;
    }
    .selected-indicator {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 5px;
      width: 100%;
      background-color: var(--secondary-color);
      opacity: 0;
    }
    .tab-title {
      font-size: 18px;
      font-weight: 500;
      opacity: 0.4;
    }
    &.selected {
      > * {
        transition: all 150ms linear 200ms;
      }
      .selected-indicator {
        opacity: 1;
      }
      .tab-title {
        opacity: 1;
      }
    }
  }
}
