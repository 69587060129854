.why-slide {
  .body-text {
    color: var(--Gray900);
    text-indent: 16px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    a {
      color: var(--NickelPrimary);
    }
  }
}
