@import url("https://fonts.googleapis.com/css?family=Montserrat:200,400,500,700&display=swap");

html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: Montserrat, sans-serif;
}
