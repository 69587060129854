.campaign-media-container {
  position: relative;
  max-width: 750px;
  height: var(--content-inner-height);
  padding: var(--content-padding) var(--content-padding) 0px;
  overflow: scroll;
  box-sizing: border-box;

  .buttons {
    position: absolute;
    bottom: 16px;
    left: 16px;
    padding: 8px;
    display: flex;
    background-color: var(--White);
    border-radius: 10px;
    box-shadow: var(--image-box-shadow);
  }
}
