.payment-processor-container {
  height: var(--content-inner-height);
  width: 100%;
  padding: var(--content-padding) var(--content-padding) 0px;
  overflow: scroll;

  .payment-progress,
  .payment-title {
    font-size: 22px;
    color: var(--Gray900);
  }

  .payment-progress {
    margin-bottom: 60px;
  }

  .payment-title {
    height: 28px;
    display: flex;
    align-items: center;
    margin: 40px 0px 20px;
  }

  .inline-icon {
    margin-left: 12px;
    margin-bottom: -2px;
  }
}
