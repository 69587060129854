.radio-button {
  --radio-size: 20px;
  --radio-check-size: 10px;

  & + .radio-button {
    margin-top: 16px;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  input[type="radio"] {
    display: none;

    &:checked + label:before {
      border-color: var(--primary-color);
      animation: radio-ripple 0.2s ease forwards;
    }

    &:checked + label:after {
      transform: scale(1);
    }
  }

  label {
    display: inline-block;
    height: var(--radio-size);
    width: 100%;
    position: relative;
    padding: 0 calc(2 * (var(--radio-size) + var(--radio-check-size))) 0 0;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;

    &:before,
    &:after {
      position: absolute;
      content: "";
      border-radius: 50%;
      transition: all 0.3s ease;
      transition-property: transform, border-color;
    }

    &:before {
      right: 0;
      top: 0;
      width: var(--radio-size);
      height: var(--radio-size);
      border: 2px solid var(--Gray600);
    }

    &:after {
      top: calc((var(--radio-size) / 2) - (var(--radio-check-size) / 2));
      right: calc((var(--radio-size) / 2) - (var(--radio-check-size) / 2));
      width: 10px;
      height: 10px;
      transform: scale(0);
      background: var(--primary-color);
    }
  }
}

@keyframes radio-ripple {
  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }

  50% {
    box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.1);
  }

  100% {
    box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0);
  }
}
