.dashboard {
  // These are all from UX spec
  --summary-number-pixel-base: 246;
  --gap-pixel-base: 24;
  --left-col-pixel-base: calc(var(--summary-number-pixel-base) * 4 + var(--gap-pixel-base) * 3);
  --trend-height-pixel-base: 381;
  --health-height-pixel-base: 381;
  --right-col-pixel-base: 516;
  --dashboard-pixel-base: calc(var(--left-col-pixel-base) + var(--gap-pixel-base) + var(--right-col-pixel-base));

  --dashboard-width: calc(100vw - var(--sidebar-width) - 2 * var(--content-padding));

  --dashboard-gap-pct: calc(var(--gap-pixel-base) / var(--dashboard-pixel-base));
  --dashboard-gap: calc(var(--dashboard-width) * var(--dashboard-gap-pct));

  --card-border-radius: 8px;
  --card-box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 16px 0 rgba(0, 0, 0, 0.08);

  height: var(--content-inner-height);
  width: 100%;
  padding: var(--content-padding) var(--content-padding) 0px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  overflow: scroll;

  .loading {
    height: 100%;
    width: 100%;
  }

  .left-col {
    --left-col-pct: calc(var(--left-col-pixel-base) / var(--dashboard-pixel-base));
    --left-col-width: calc(var(--dashboard-width) * var(--left-col-pct));

    flex-shrink: 0;
    width: var(--left-col-width);
    margin-right: var(--dashboard-gap);

    .summary-numbers {
      width: 100%;
      margin-bottom: var(--dashboard-gap);
      display: flex;
      justify-content: space-between;

      .summary-number {
        --summary-number-pct: calc(var(--summary-number-pixel-base) / var(--dashboard-pixel-base));
        --summary-number-width: calc(var(--dashboard-width) * var(--summary-number-pct));

        height: var(--summary-number-width);
        width: var(--summary-number-width);
        padding: 16px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: var(--White);
        border-radius: var(--card-border-radius);
        box-shadow: var(--card-box-shadow);

        &:nth-child(1) .icon-wrapper {
          background-color: var(--Purple);
        }

        &:nth-child(2) .icon-wrapper {
          background-color: var(--Blue);
        }

        &:nth-child(3) .icon-wrapper {
          background-color: var(--Orange);
        }

        &:nth-child(4) .icon-wrapper {
          background-color: var(--Pink);
        }

        .icon-wrapper {
          --wrapper-pixel-base: 40;
          --wrapper-pct: calc(var(--wrapper-pixel-base) / var(--summary-number-pixel-base));
          --wrapper-size: calc(var(--summary-number-width) * var(--wrapper-pct));
          height: var(--wrapper-size);
          width: var(--wrapper-size);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: calc(var(--wrapper-size) / 2);

          .summary-icon {
            --pixel-base: 20;
            --pct: calc(var(--pixel-base) / var(--summary-number-pixel-base));
            --size: calc(var(--summary-number-width) * var(--pct));
            height: var(--size);
            width: var(--size);
            color: #fff;
          }
        }

        .value {
          --font-size-base: 54;
          --font-size-pct: calc(var(--font-size-base) / var(--summary-number-pixel-base));
          --font-size: calc(var(--summary-number-width) * var(--font-size-pct));
          font-size: var(--font-size);

          font-weight: 500;

          --letter-spacing-base: -2;
          --letter-spacing-pct: calc(var(--letter-spacing-base) / var(--summary-number-pixel-base));
          --letter-spacing: calc(var(--summary-number-width) * var(--letter-spacing-pct));
          letter-spacing: var(--letter-spacing);
        }

        .title {
          --font-size-base: 16;
          --font-size-pct: calc(var(--font-size-base) / var(--summary-number-pixel-base));
          --font-size: calc(var(--summary-number-width) * var(--font-size-pct));
          font-size: var(--font-size);

          --line-height-base: 24;
          --line-height-pct: calc(var(--line-height-base) / var(--summary-number-pixel-base));
          --line-height: calc(var(--summary-number-width) * var(--line-height-pct));
          line-height: var(--line-height);
        }

        .indicator {
          --font-size-base: 14;
          --font-size-pct: calc(var(--font-size-base) / var(--summary-number-pixel-base));
          --font-size: calc(var(--summary-number-width) * var(--font-size-pct));
          font-size: var(--font-size);

          --line-height-base: 24;
          --line-height-pct: calc(var(--line-height-base) / var(--summary-number-pixel-base));
          --line-height: calc(var(--summary-number-width) * var(--line-height-pct));
          line-height: var(--line-height);

          &.positive {
            color: var(--Green);
          }

          &.negative {
            color: var(--Red);
          }

          &.empty {
            color: var(--White);
            user-select: none;
          }
        }
      }
    }

    .campaign-health {
      --health-height-pct: calc(var(--health-height-pixel-base) / var(--dashboard-pixel-base));
      --health-height: calc(var(--dashboard-width) * var(--health-height-pct));

      width: 100%;
      height: calc(var(--health-height));
      background-color: var(--White);
      border-radius: var(--card-border-radius);
      box-shadow: var(--card-box-shadow);
    }
  }

  .right-col {
    --right-col-pct: calc(var(--right-col-pixel-base) / var(--dashboard-pixel-base));
    --right-col-width: calc(var(--dashboard-width) * var(--right-col-pct));

    flex-shrink: 0;
    width: var(--right-col-width);
  }
}
