.summary-numbers {
  width: 100%;
  margin: var(--content-padding) 0px;
  display: grid;
  grid-template: 1fr / repeat(4, 1fr);
  grid-gap: var(--content-padding);

  .summary-number {
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;

    .icon-wrapper {
      background-color: var(--primary-color);
    }

    .icon-wrapper {
      --wrapper-size: 32px;
      height: var(--wrapper-size);
      width: var(--wrapper-size);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      .summary-icon {
        height: 16px;
        width: 16px;
        color: var(--White);
      }
    }

    .value {
      text-align: center;
      font-size: 32px;
      font-weight: 500;
      letter-spacing: -0.5px;
      margin-top: 8px;
    }

    .title {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      margin-top: 8px;
    }

    .indicator {
      margin-top: 4px;
      color: var(--Yellow);
      font-size: 10px;
      font-weight: 600;

      &.empty {
        color: var(--White);
        user-select: none;
      }
    }
  }
}
