.auth-header {
  position: relative;
  z-index: 1;
  height: 40px;
  width: 977px;
  margin-top: 40px;
  padding: 0 162px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .nav-button {
    height: 40px;
    width: 130px;
    margin: 0 6px;
    padding: 0;
    border-radius: 5px;
    outline: none;
    border: 2px solid #2487ff;
    color: #2487ff;
    background-color: transparent;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;
    cursor: pointer;
    text-decoration: none;

    &:active {
      opacity: 0.9;
    }

    &.primary {
      color: #fff;
      background-color: #2487ff;
    }

    &.ancillary {
      width: 80px;
      color: #000;
      border-color: transparent;
      font-weight: 700;
    }
  }
}
