.teams-table-container {
  --container-height: calc(
    var(--content-inner-height) - 2 * var(--content-padding)
  );

  height: var(--container-height);
  display: flex;
  flex-direction: column;

  .teams-table-title {
    flex-shrink: 0;
    height: calc(2 * var(--content-padding));
    display: flex;
    font-size: 28px;
  }

  .ka {
    --table-height: calc(var(--container-height) - 2 * var(--content-padding));
    --header-row-height: 48px;

    height: var(--table-height);

    .ka-tbody {
      max-height: calc(var(--table-height) - var(--header-row-height));

      > .ka-tr:nth-child(even) {
        .profile-picture-placeholder {
          background-color: var(--Gray300);
        }
      }
    }
  }

  .team-cell {
    height: 60px;
    width: 228px;
    display: flex;
    align-items: center;

    .profile-picture,
    .profile-picture-placeholder {
      height: 48px;
      width: 48px;
      margin-right: 20px;
      border-radius: 8px;
      background-color: transparent;
    }
    .profile-picture-placeholder {
      background-color: var(--Gray200);
    }

    .team-name {
      width: 144px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .social-cell {
    height: 40px;
    display: grid;
    grid-template: 1fr / 40px 1fr;
    align-items: center;

    .social-cell-image {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 25px;
    }

    .social-cell-handle {
      margin-left: 8px;
      min-width: 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .social-cell-placeholder {
    height: 40px;
  }

  .ellipsis-cell {
    min-width: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .delete-cell {
    color: var(--Gray400);
  }
}
