.donor-details-box {
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  width: var(--detail-width);
  padding: 24px;
  box-sizing: border-box;
  color: var(--Gray900);
  background-color: var(--White);
  border-radius: 8px;
  box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 16px 0 rgba(0, 0, 0, 0.08);
  overflow: scroll;

  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .donor-details-user {
    height: 144px;
    width: 144px;
    margin: 0px auto 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid var(--Green);
    border-radius: 50%;

    .donor-details-user-profile {
      height: 134px;
      width: 134px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--Gray200);
      border-radius: 50%;
    }
  }

  .donor-details-name {
    margin-bottom: 8px;
    color: var(--Gray900);
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }

  .donor-details-info-row {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .info-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .title-line {
        font-size: 14px;
        font-weight: 500;
      }

      .detail-line {
        color: var(--Gray400);
        font-size: 12px;
        text-align: center;
      }
    }

    .divider {
      align-self: stretch;
      width: 1px;
      background-color: var(--Gray200);
    }
  }

  .mobile-usage-row {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon-wrapper {
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--primary-color);
      border-radius: 50%;
    }

    .details-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .details-title {
        color: var(--Gray400);
        font-size: 10px;
      }

      .details-text {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .info-row {
    margin: 0px -24px;
    margin-bottom: 12px;
    padding: 0px 24px;
    padding-top: 12px;

    &.clickable {
      cursor: pointer;
    }

    &--with-top-border {
      margin-bottom: 0;
      border-top: 1px solid var(--Gray200);
    }

    .info-title {
      margin-bottom: 2px;
      color: var(--Gray400);
      font-size: 11px;
    }

    .info-text {
      font-size: 12px;
      font-weight: 500;

      .info-text-checkbox-wrapper {
        width: 100%;
        margin-bottom: -4px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
