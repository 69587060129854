.payout {
  height: var(--content-inner-height);
  width: 100%;
  padding: var(--content-padding) var(--content-padding) 0px;
  box-sizing: border-box;
  display: grid;
  grid-template: 1fr / 250px 1fr;
  grid-gap: 24px;
  overflow: scroll;

  .payout-details {
    height: calc(var(--content-inner-height) - 2 * var(--content-padding));
    padding: 24px;
    box-sizing: border-box;
    color: var(--Gray900);
    background-color: var(--White);
    border-radius: 8px;
    box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 16px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;

    .payout-detail-icon-container {
      width: 202px;
      margin-top: 8px;
      margin-bottom: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
    }

    .detail-item {
      padding-top: 24px;
      width: 100%;

      .detail-item-title {
        margin-bottom: 4px;
        color: var(--Gray500);
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }

      .detail-item-value {
        margin-top: 2px;
        font-size: 20px;
      }

      &.bold {
        .detail-item-value {
          font-size: 32px;
          font-weight: 500;
        }
      }

      .negative {
        color: var(--Red);
      }
    }
  }
}
