@import "node_modules/ka-table/style.scss";

.oauth-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  z-index: 300;

  .title {
    margin-bottom: 16px;
  }
}
