.clubhub-levels {
  width: 100%;
  height: var(--content-inner-height);
  padding: var(--content-padding) var(--content-padding) 0px;
  overflow: scroll;
  box-sizing: border-box;

  .membership-squares-title {
    height: 18px;
    margin-top: 48px;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  .membership-squares {
    display: grid;
    grid-template: repeat(auto-fit, 250px) / repeat(
        auto-fit,
        minmax(300px, 1fr)
      );
    grid-gap: 24px;
    margin-bottom: 24px;

    &.single {
      grid-template-columns: repeat(auto-fit, minmax(300px, 375px));
    }

    .membership-square {
      height: 250px;
      max-width: 450px;
      background-color: #fff;
      border-radius: 12px;
      box-sizing: border-box;
      overflow: hidden;
      box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12),
        0 2px 16px 0 rgba(0, 0, 0, 0.08);
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }
      &:active {
        opacity: 0.7;
      }

      .banner-image {
        height: 50%;
        width: 100%;
        padding: 12px 0px;
        box-sizing: border-box;
        display: block;
        background-color: #eaeaea;
        object-fit: contain;
      }

      .banner-placeholder {
        height: 50%;
        width: 100%;
        background-color: #eaeaea;
      }

      .info-wrapper {
        --vertical-padding-width-ratio: 12 / var(--full-width);
        --horiz-padding-width-ratio: 16 / var(--full-width);

        width: 100%;
        padding: 10px 14px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .titles {
          width: 100%;
          height: 50px;
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .info-title {
            max-width: 100%;
            min-height: 25px;
            font-size: 20px;
            font-weight: 500;
            color: var(--Gray900);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .info-description {
            font-size: 10px;
            font-weight: 300;
            color: #888;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .metrics {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .metric-wrapper {
            &.donated {
              flex: 1 1 0;
            }

            &.donors {
              flex: 1 1 0;
            }

            &.days-left {
              flex: 1 1 0;
            }

            .metric {
              font-size: 18px;
              font-weight: 500;
              color: var(--Gray900);
            }

            .label {
              font-size: 10px;
              font-weight: 300;
              color: var(--Gray500);
              min-width: 50px;
            }
          }
        }
      }
    }
  }
}
