.home-messages {
  width: 100%;
  height: var(--content-inner-height);
  padding: var(--content-padding) var(--content-padding) 0px;
  overflow: scroll;
  box-sizing: border-box;

  .zero-state {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .zero-state-message {
      font-size: 32px;
      color: var(--Gray300);
      font-weight: 400;
    }
  }
}
