// Inherits variables from ../Statistics.scss
.revenue-trend {
  --trend-height-pct: calc(var(--trend-height-pixel-base) / var(--dashboard-pixel-base));
  --trend-height: calc(var(--dashboard-width) * var(--trend-height-pct));

  --Yellow: #fbcd1d;
  --Green: #29c4b2;

  width: 100%;
  height: calc(var(--trend-height));
  margin-bottom: var(--dashboard-gap);
  padding: calc(var(--dashboard-gap) * 2 / 3) var(--dashboard-gap);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: var(--White);
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-box-shadow);
  overflow: hidden;

  .chart-title {
    flex-shrink: 0;

    --font-size-base: 16;
    --font-size-pct: calc(var(--font-size-base) / var(--trend-height-pixel-base));
    --font-size: calc(var(--trend-height) * var(--font-size-pct));
    font-size: var(--font-size);

    --line-height-base: 24;
    --line-height-pct: calc(var(--line-height-base) / var(--trend-height-pixel-base));
    --line-height: calc(var(--trend-height) * var(--line-height-pct));
    line-height: var(--line-height);
  }

  .no-data {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    --font-size-base: 40;
    --font-size-pct: calc(var(--font-size-base) / var(--trend-height-pixel-base));
    --font-size: calc(var(--trend-height) * var(--font-size-pct));
    font-size: var(--font-size);

    color: var(--Gray300);
  }
}
