.transaction-detail {
  height: var(--content-inner-height);
  width: 100%;
  padding: var(--content-padding) var(--content-padding) 0px;
  box-sizing: border-box;
  display: grid;
  grid-template: 1fr / 250px 1fr;
  grid-gap: 24px;
  overflow: scroll;

  .transaction-details {
    height: calc(var(--content-inner-height) - 2 * var(--content-padding));
    padding: 24px;
    box-sizing: border-box;
    color: var(--Gray900);
    background-color: var(--White);
    border-radius: 8px;
    box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 16px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;

    .transaction-user-profile {
      height: 202px;
      width: 202px;
      margin-bottom: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--Gray200);
      border-radius: 8px;

      * {
        stroke-width: 1 !important;
      }
    }

    .detail-item {
      padding-top: 24px;
      width: 100%;

      .detail-item-title {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.5px;
      }

      .detail-item-value {
        margin-top: 2px;
        font-size: 14px;
        font-weight: 300;
      }
    }
  }

  .transaction-info {
    .section-title {
      flex-shrink: 0;
      margin-top: 6px;
      margin-bottom: 10px;
      display: flex;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: var(--Gray500);

      &:not(:first-child) {
        margin-top: 56px;
      }
    }

    .section-info {
      font-size: 24px;
      color: var(--Gray900);
      font-weight: 300;

      .info-bold {
        font-weight: 500;
      }

      .card-info {
        width: max-content;
        font-size: 16px;

        .card-number {
          display: flex;
          align-items: center;

          .card-dots {
            margin-top: -4px;
            font-size: 32px;
          }
        }

        .card-brand-row {
          display: flex;
          justify-content: space-between;
          font-size: 12px;

          > :first-child {
            margin-right: 8px;
          }
        }
      }
    }

    .action-buttons {
      > * {
        margin-bottom: 24px;
      }
    }
  }
}
