.auth-button {
  height: 40px;
  width: 320px;
  margin-top: 30px;
  background-color: var(--NickelPrimary);
  color: var(--White);
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.5px;
  font-weight: 700;
  cursor: pointer;

  &:active {
    opacity: 0.9;
  }

  &--loading {
    opacity: 0.7;
    pointer-events: 'none';
    background-color: var(--app-primary-color);
  }
}
