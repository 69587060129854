@import "node_modules/ka-table/style.scss";

.teams-container {
  padding: var(--content-padding) var(--content-padding) 0px;
}

// .teams {
//   height: 100%;

//   > .header {
//     height: 90px;
//     width: calc(100% - 30px);
//     margin: 0 15px;
//     padding: 20px 5px;
//     box-sizing: border-box;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     // background-color: #fafafa;
//     // border-bottom: 1px solid #ddd;

//     .search-wrapper {
//       height: 100%;
//       // width: 500px;
//       width: 55%;
//       padding-left: 40px;
//       padding-right: 20px;
//       box-sizing: border-box;
//       display: flex;
//       align-items: center;
//       background-color: #fff;
//       border: 1px solid #ddd;
//       border-radius: 10px;
//       box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);

//       input {
//         background-color: transparent;
//         outline: none;
//         border: none;
//         font-size: 16px;

//         &::placeholder {
//           color: #ccc;
//         }
//       }
//     }

//     .add-team {
//       cursor: pointer;
//     }
//   }
// }
