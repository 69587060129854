.privacy-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background-color: var(--Gray50);
  z-index: 50;
  overflow-y: scroll;

  .wrapper {
    position: relative;
    display: flex;
  }

  .auth-header {
    position: relative;
    z-index: 1;
    height: 40px;
    width: unset;
    margin-top: 40px;
    padding: unset;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content {
    position: relative;
    flex: 1;
    margin: 0 auto;
    max-width: 977px;
    z-index: 1;
  }

  .title {
    margin-top: 80px;
    margin-bottom: 50px;
    font-size: 26px;
    line-height: 24px;
    font-weight: 700;
  }

  .section-header,
  .subsection-header {
    margin: 20px 0;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.5px;
  }

  .section-header {
    font-weight: 700;
    text-transform: uppercase;
  }

  .subsection-header {
    font-weight: 400;
  }

  .section-text {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 24px;
  }

  .list-header {
    margin: 20px 0;
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
  }

  ul {
    list-style: none;
  }

  li {
    display: flex;
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
  }

  .bullet {
    margin-left: -5px;
    margin-right: 20px;
  }

  .content p:last-child {
    margin-bottom: 100px;
  }

  @media only screen and (max-width: 1248px) {
    .content {
      padding: 0 72px;
    }
  }

  @media only screen and (max-width: 882px) {
    .content {
      padding: 0 20px;
    }
  }
}
