.forgot-password-container {
  .auth-square {
    min-height: unset;

    .forgot-password {
      font-size: 14px;
      line-height: 22px;
      opacity: 0.3;
      cursor: pointer;
      user-select: none;

      &:active {
        opacity: 0.2;
      }
    }
  }
}
