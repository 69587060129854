.team {
  --width: calc(100vw - var(--sidebar-width));
  height: var(--content-inner-height);
  width: var(--width);
  display: flex;

  .team-content {
    --content-width: calc(var(--width));
    height: 100%;
    width: var(--content-width);
    padding: var(--content-padding);
    box-sizing: border-box;
    display: flex;

    .team-details-container {
      --team-details-pixel-base: 713;
      --team-title-pixel-base: 200;
      --team-owner-pixel-base: 40;
      --team-roster-pixel-base: 40;
      --team-social-pixel-base: 330;

      --team-picture-height-base: 150;
      --team-picture-width-base: 200;

      --team-social-container-base: 75;

      --team-social-profile-base: 50;

      --team-details-height: calc(
        100vh - var(--header-height) - var(--page-header-height) - (var(--page-header-spacing) * 2) -
          (var(--content-padding) * 2)
      );

      min-width: 300px;
      height: var(--team-details-height);
      padding: 24px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      background-color: var(--White);
      border-radius: 8px;
      box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 16px 0 rgba(0, 0, 0, 0.08);
      overflow-y: auto;

      .label {
        --font-size-base: 12;
        --font-size-pct: calc(var(--font-size-base) / var(--team-details-pixel-base));
        --font-size: calc(var(--team-details-height) * var(--font-size-pct));
        font-size: var(--font-size);
        color: var(--Gray500);
        margin-bottom: 4px;
      }

      .team-title-container {
        --team-title-pct: calc(var(--team-title-pixel-base) / var(--team-details-pixel-base));
        --team-title-height: calc(var(--team-details-height) * var(--team-title-pct));

        // height: var(--team-title-height);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 16px;

        .team-picture {
          --team-picture-pct: calc(var(--team-picture-height-base) / var(--team-title-pixel-base));
          --team-picture-height: calc(var(--team-title-height) * var(--team-picture-pct));

          height: var(--team-picture-height);
          width: calc(var(--team-picture-height) * (4 / 3));
        }

        .team-icon {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .team-icon-container {
            padding: 4px 16px;
            border-radius: 4px;
            overflow: hidden;
          }

          .editing {
            background-color: var(--Gray200);
            cursor: pointer;
            &:hover {
              opacity: 0.9;
            }
            &:active {
              opacity: 0.7;
            }
          }
        }

        .team-name-container {
          margin-top: 16px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          .team-name {
            --font-size-base: 24;
            --font-size-pct: calc(var(--font-size-base) / var(--team-title-pixel-base));
            --font-size: calc(var(--team-title-height) * var(--font-size-pct));
            font-size: var(--font-size);
            color: var(--gray700);
          }
        }
      }

      .team-owner-container {
        width: 100%;
        margin-bottom: 24px;

        .team-owner {
          --font-size-base: 16;
          --font-size-pct: calc(var(--font-size-base) / var(--team-owner-pixel-base));
          --font-size: calc(var(--team-owner-height) * var(--font-size-pct));
          font-size: var(--font-size);
          margin-top: 8px;
          color: var(--gray300);
        }
      }

      .team-roster-container {
        --team-roster-pct: calc(var(--team-roster-pixel-base) / var(--team-details-pixel-base));
        --team-roster-height: calc(var(--team-details-height) * var(--team-roster-pct));
        height: var(--team-roster-height);
        width: 100%;
        margin-bottom: 24px;

        .team-roster {
          --font-size-base: 16;
          --font-size-pct: calc(var(--font-size-base) / var(--team-roster-pixel-base));
          --font-size: calc(var(--team-roster-height) * var(--font-size-pct));
          font-size: var(--font-size);
          margin-top: 8px;
          color: var(--gray300);
        }
      }

      .team-social-container {
        --team-social-pct: calc(var(--team-social-pixel-base) / var(--team-details-pixel-base));
        --team-social-height: calc(var(--team-details-height) * var(--team-social-pct));
        height: var(--team-social-height);
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .social-auth {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .social-container {
            --team-social-container-pct: calc(var(--team-social-container-base) / var(--team-social-pixel-base));
            --team-social-container-height: calc(var(--team-social-height) * var(--team-social-container-pct));
            height: var(--team-social-container-height);
            width: 100%;
            position: relative;
            margin: 4px 0px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-radius: 10px;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);

            &.add {
              cursor: pointer;
              &:hover {
                opacity: 0.7;
              }
            }

            .social-delete {
              height: 20px;
              width: 20px;
              top: -10px;
              right: -10px;
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              color: var(--Gray500);
              background-color: #fff;
              border: 1px solid #ddd;
              border-radius: 24px;
            }

            .icon {
              --font-size-base: 48;
              --font-size-pct: calc(var(--font-size-base) / var(--team-social-container-base));
              --font-size: calc(var(--team-social-container-height) * var(--font-size-pct));
              font-size: var(--font-size);
              margin: 0px 8px;
              color: black;

              svg {
                height: 100%;
                // width: 100%;
              }

              &.instagram {
                color: #d6249f;
                // color: radial-gradient(
                //   circle at 30% 107%,
                //   #fdf497 0%,
                //   #fdf497 5%,
                //   #fd5949 45%,
                //   #d6249f 60%,
                //   #285aeb 90%
                // );
              }

              &.twitter {
                color: #1da1f2;
              }

              &.facebook {
                color: #3c5a99;
              }

              &.filled {
                color: white;
              }
            }

            .social-profile-picture {
              --team-social-profile-pct: calc(var(--team-social-profile-base) / var(--team-social-container-base));
              --team-social-profile-height: calc(var(--team-social-container-height) * var(--team-social-profile-pct));
              height: var(--team-social-profile-height);
              width: var(--team-social-profile-height);
              margin: 0px 8px;
              border-radius: 25px;
            }

            .social-support-text {
              --font-size-base: 16;
              --font-size-pct: calc(var(--font-size-base) / var(--team-social-container-base));
              --font-size: calc(var(--team-social-container-height) * var(--font-size-pct));
              font-size: var(--font-size);
              color: var(--Gray500);
              max-width: 150px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              &.add {
                color: var(--Gray300);
              }
            }
          }
        }
      }
    }

    .team-members-container {
      max-height: var(--content-inner-height);
      flex-grow: 1;
      padding-left: var(--content-padding);
    }
  }
}
