.bulletin-preview-container {
  width: 100%;
  padding-bottom: var(--content-padding);

  .preview-box {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
    background-color: var(--White);

    &.spaced {
      margin-bottom: 32px;
    }

    .message-item-preview {
      width: 100%;
      display: flex;
      overflow: hidden;

      .message-item-image {
        height: 40px;
        width: 40px;
        margin-right: 16px;
        border-radius: 3px;
        background-color: var(--app-primary-color);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .message-item-text {
        flex-grow: 1;

        .message-item-title {
          max-height: 44px;
          font-size: 18px;
          line-height: 22px;
          overflow: hidden;
        }
        .message-item-body {
          margin-top: 4px;
          font-size: 12px;
          color: var(--Gray300);
        }
      }
    }

    .title-row {
      display: flex;
      align-items: center;

      .account-image {
        height: 40px;
        width: 40px;
        margin-right: 16px;
        background-color: var(--primary-color);
        border-radius: 50%;
      }
      .account-name {
        font-weight: 600;
        color: var(--primary-color);
        font-size: 14px;
      }
    }
    .bulletin-text {
      padding: 12px 0;
      font-size: 14px;

      &.message-item {
        font-size: 12px;
      }
    }

    .bulletin-image {
      width: calc(100% + 32px);
      margin: 0px -16px;
      background-color: var(--Gray300);

      &.message-item {
        width: 300px;
        margin: 16px auto 48px;
        border-radius: 4px;
      }

      .preview-image {
        width: 100%;
        object-fit: cover;
        background-color: var(--White);
        border-radius: 4px;
      }

      &.message-item .preview-image {
        width: 300px;
      }
    }

    .bulletin-preview-bottom {
      background-color: #f2f3f5;
      margin: 0px -16px -16px;
      padding: 0px 16px 16px;

      .bulletin-url {
        padding: 8px 0 6px;
        color: var(--Gray300);
        font-size: 14px;
        font-weight: 500;
      }

      .bulletin-campaign-name {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
