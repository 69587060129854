.notes {
  height: calc(var(--content-inner-height) - var(--tab-bar-height) - var(--content-padding));
  width: var(--content-width);
  padding: var(--content-padding);
  padding-bottom: 0px;
  box-sizing: border-box;
  overflow-y: scroll;

  .notes-title {
    height: 18px;
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  .new-note {
    z-index: 1;
    height: 225px;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    display: flex;
    background-color: var(--White);
    border-radius: 8px;
    box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 16px 0 rgba(0, 0, 0, 0.08);

    .note-input {
      flex: 1;
      padding: 12px 8px;
      font-size: 14px;
      font-weight: 500;
      border: none;
      outline: none;
      resize: none;

      &::placeholder {
        color: var(--Gray500);
      }
    }

    .button-col {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  .profile-picture-wrapper {
    flex-shrink: 0;
    height: 40px;
    width: 40px;
    margin-right: 12px;
    background-color: var(--Gray400);
    border-radius: 50%;
    overflow: hidden;

    .profile-image {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .existing-notes {
    display: flex;
    max-height: 400px;
    padding-top: calc(2 * var(--content-padding));
    padding-right: var(--content-padding);

    .months {
      flex-shrink: 0;
      width: 144px;
      margin-right: calc(2 * var(--content-padding));

      .months-title {
        height: 18px;
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }
      .month-links {
        .month-link {
          height: 18px;
          margin-bottom: 6px;
          color: var(--primary-color);
          font-size: 12px;
          line-height: 18px;
          font-weight: 600;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          cursor: pointer;

          &:hover {
            opacity: 0.9;
          }

          &:active {
            opacity: 0.7;
          }
        }
      }
    }

    .note-items {
      .note-item {
        width: 100%;
        padding: 16px 12px;
        margin-bottom: var(--content-padding);
        background-color: var(--White);
        border-radius: 8px;
        box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 16px 0 rgba(0, 0, 0, 0.08);

        .details-row {
          width: 100%;
          display: flex;
          align-items: center;

          .user-info {
            flex: 1;

            .user-name {
              font-size: 12px;
            }

            .user-position {
              color: var(--Gray400);
              font-size: 10px;
            }
          }

          .system-info {
            margin-right: 12px;
            .date {
              font-size: 12px;
            }

            .system-name {
              color: var(--Gray400);
              font-size: 10px;
              text-align: right;
            }
          }

          .more-button {
            height: 28px;
            width: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid var(--Gray400);
            border-radius: 4px;
            cursor: pointer;

            &:hover {
              opacity: 0.9;
            }

            &:active {
              opacity: 0.7;
            }
          }
        }

        .message-contents {
          margin-top: 16px;
          font-size: 14px;

          .message-subject {
            font-weight: 700;
          }
        }
      }
    }
  }
}
