.messages {
  position: relative;
  height: calc(var(--content-inner-height) - var(--tab-bar-height) - 2 * var(--content-padding));
  width: var(--content-width);
  padding-bottom: var(--content-padding);
  box-sizing: border-box;

  .messages-container {
    height: calc(var(--content-inner-height) - var(--tab-bar-height) - 4 * var(--content-padding));
    padding: 0px var(--content-padding);
    overflow: scroll;

    .message-wrapper {
      width: 100%;
      margin-top: 24px;
      display: flex;

      &.last {
        padding-bottom: var(--content-padding);
      }

      .profile-picture-wrapper {
        flex-shrink: 0;
        height: 40px;
        width: 40px;
        margin-right: 12px;
        background-color: var(--White);
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.08) inset;

        .profile-image {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .message-info-wrapper {
        flex: 1;
        max-width: 70%;
        display: flex;
        flex-direction: column;

        .message-info-row {
          color: var(--Gray400);
          font-size: 10px;

          .user-name {
            margin-right: 2px;
            color: var(--Gray900);
            font-size: 12px;
            font-weight: 600;
          }
        }

        .message-contents {
          margin-top: 8px;
          font-size: 14px;
        }

        .read-indicator {
          margin-top: 8px;
          color: var(--Gray400);
          font-size: 10px;
        }
      }

      &.mine {
        flex-direction: row-reverse;

        .profile-picture-wrapper {
          margin-right: 0px;
          margin-left: 12px;
        }

        .message-info-wrapper {
          align-items: flex-end;

          .message-contents {
            text-align: right;
          }
        }
      }
    }

    .zero-state-wrapper {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .zero-state-message {
        max-width: 50%;
        color: var(--Gray300);
        font-size: 20px;
        font-weight: 400;
        text-align: center;
      }
    }
  }

  .message-input-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 48px;
    width: 100%;
    padding: 4px;
    box-sizing: border-box;
    display: flex;
    background-color: var(--White);
    border-radius: 24px;
    box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 16px 0 rgba(0, 0, 0, 0.08);

    .icon-wrapper {
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 0;
      cursor: pointer;

      &:active {
        opacity: 0.7;

        &.open {
          opacity: 1;
        }
      }

      &.emoji {
        position: relative;
        background-color: var(--Gray300);
      }
      &.attachment {
        transform: rotate(-45deg);
        transform-origin: 50% 50%;
      }
      &.send {
        background-color: var(--primary-color);
        transform: rotate(-45deg);
        transform-origin: 50% 50%;

        > :first-child {
          margin-left: 4px;
        }
      }
    }

    .message-input {
      flex: 1;
      padding: 0px 8px;
      font-size: 14px;
      font-weight: 500;
      border: none;
      outline: none;

      &::placeholder {
        color: var(--Gray500);
      }
    }

    .emoji {
      .emoji-picker-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
}
