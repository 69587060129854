.user {
  --width: calc(100vw - var(--sidebar-width));
  height: var(--content-inner-height);
  width: var(--width);
  display: flex;

  .user-content {
    --content-width: calc(var(--width));
    height: 100%;
    width: var(--content-width);
    padding: var(--content-padding);
    box-sizing: border-box;
    overflow: scroll;
    display: flex;

    .user-details-container {
      --user-details-pixel-base: 713;
      --user-title-pixel-base: 200;
      --user-role-pixel-base: 40;
      --user-email-pixel-base: 40;
      --user-status-pixel-base: 40;
      --user-social-pixel-base: 330;

      --user-picture-height-base: 150;
      --user-picture-width-base: 200;

      --user-social-container-base: 75;

      --user-social-profile-base: 50;

      --user-details-height: calc(
        100vh - var(--header-height) - var(--page-header-height) -
          (var(--page-header-spacing) * 2) - (var(--content-padding) * 2)
      );

      // width: 100%;
      height: var(--user-details-height);
      padding: 32px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      background-color: var(--White);
      border-radius: 8px;
      box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12),
        0 2px 16px 0 rgba(0, 0, 0, 0.08);

      .label {
        --font-size-base: 12;
        --font-size-pct: calc(
          var(--font-size-base) / var(--user-details-pixel-base)
        );
        --font-size: calc(var(--user-details-height) * var(--font-size-pct));
        font-size: var(--font-size);
        color: var(--Gray500);
        margin-bottom: 4px;
      }

      .user-title-container {
        --user-title-pct: calc(
          var(--user-title-pixel-base) / var(--user-details-pixel-base)
        );
        --user-title-height: calc(
          var(--user-details-height) * var(--user-title-pct)
        );

        // height: var(--user-title-height);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 16px;

        .user-picture {
          --user-picture-pct: calc(
            var(--user-picture-height-base) / var(--user-title-pixel-base)
          );
          --user-picture-height: calc(
            var(--user-title-height) * var(--user-picture-pct)
          );

          height: var(--user-picture-height);
          width: calc(var(--user-picture-height) * (4 / 3));

          img {
            border-radius: 8px;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        .user-name-container {
          margin-top: 16px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          .user-name {
            --font-size-base: 24;
            --font-size-pct: calc(
              var(--font-size-base) / var(--user-title-pixel-base)
            );
            --font-size: calc(var(--user-title-height) * var(--font-size-pct));
            font-size: var(--font-size);
            color: var(--gray700);
          }
        }
      }

      .user-role-container {
        --user-role-pct: calc(
          var(--user-role-pixel-base) / var(--user-details-pixel-base)
        );
        --user-role-height: calc(
          var(--user-details-height) * var(--user-role-pct)
        );
        height: var(--user-role-height);
        width: 100%;
        margin-bottom: 24px;
        .user-role {
          --font-size-base: 16;
          --font-size-pct: calc(
            var(--font-size-base) / var(--user-role-pixel-base)
          );
          --font-size: calc(var(--user-role-height) * var(--font-size-pct));
          font-size: var(--font-size);
          margin-top: 8px;
          color: var(--gray300);
        }
      }

      .user-email-container {
        --user-email-pct: calc(
          var(--user-email-pixel-base) / var(--user-details-pixel-base)
        );
        --user-email-height: calc(
          var(--user-details-height) * var(--user-email-pct)
        );
        height: var(--user-email-height);
        width: 100%;
        margin-bottom: 24px;

        .user-email {
          --font-size-base: 16;
          --font-size-pct: calc(
            var(--font-size-base) / var(--user-email-pixel-base)
          );
          --font-size: calc(var(--user-email-height) * var(--font-size-pct));
          font-size: var(--font-size);
          margin-top: 8px;
          color: var(--gray300);
        }
      }

      .user-status-container {
        --user-status-pct: calc(
          var(--user-status-pixel-base) / var(--user-details-pixel-base)
        );
        --user-status-height: calc(
          var(--user-details-height) * var(--user-status-pct)
        );
        height: var(--user-status-height);
        width: 100%;
        margin-bottom: 24px;

        .user-status {
          --font-size-base: 16;
          --font-size-pct: calc(
            var(--font-size-base) / var(--user-status-pixel-base)
          );
          --font-size: calc(var(--user-status-height) * var(--font-size-pct));
          font-size: var(--font-size);
          margin-top: 8px;
          color: var(--gray300);
        }

        .user-resend {
          font-size: 14px;
          font-variant: small-caps;
          color: var(--NickelPrimaryLight);
          cursor: pointer;

          &:hover {
            opacity: 0.7;
          }
        }
      }

      .user-social-container {
        --user-social-pct: calc(
          var(--user-social-pixel-base) / var(--user-details-pixel-base)
        );
        --user-social-height: calc(
          var(--user-details-height) * var(--user-social-pct)
        );
        height: var(--user-social-height);
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .social-auth {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .social-container {
            --user-social-container-pct: calc(
              var(--user-social-container-base) / var(--user-social-pixel-base)
            );
            --user-social-container-height: calc(
              var(--user-social-height) * var(--user-social-container-pct)
            );
            height: var(--user-social-container-height);
            width: 100%;
            position: relative;
            margin: 4px 0px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-radius: 10px;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
            cursor: pointer;

            .social-delete {
              height: 20px;
              width: 20px;
              top: -10px;
              right: -10px;
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              color: var(--Gray500);
              background-color: #fff;
              border: 1px solid #ddd;
              border-radius: 24px;
            }

            .icon {
              --font-size-base: 48;
              --font-size-pct: calc(
                var(--font-size-base) / var(--user-social-container-base)
              );
              --font-size: calc(
                var(--user-social-container-height) * var(--font-size-pct)
              );
              font-size: var(--font-size);
              margin: 0px 8px;
              color: black;

              svg {
                height: 100%;
                // width: 100%;
              }

              &.instagram {
                color: #d6249f;
                // color: radial-gradient(
                //   circle at 30% 107%,
                //   #fdf497 0%,
                //   #fdf497 5%,
                //   #fd5949 45%,
                //   #d6249f 60%,
                //   #285aeb 90%
                // );
              }

              &.twitter {
                color: #1da1f2;
              }

              &.facebook {
                color: #3c5a99;
              }

              &.filled {
                color: white;
              }
            }

            .social-profile-picture {
              --user-social-profile-pct: calc(
                var(--user-social-profile-base) /
                  var(--user-social-container-base)
              );
              --user-social-profile-height: calc(
                var(--user-social-container-height) *
                  var(--user-social-profile-pct)
              );
              height: var(--user-social-profile-height);
              width: var(--user-social-profile-height);
              margin: 0px 8px;
              border-radius: 25px;
            }

            .social-support-text {
              --font-size-base: 16;
              --font-size-pct: calc(
                var(--font-size-base) / var(--user-social-container-base)
              );
              --font-size: calc(
                var(--user-social-container-height) * var(--font-size-pct)
              );
              font-size: var(--font-size);
              color: var(--Gray500);
              max-width: 150px;
              text-overflow: ellipsis;

              &.add {
                color: var(--Gray300);
              }
            }
          }
        }
      }
    }

    .user-permissions-container {
      position: relative;
      max-height: var(--content-inner-height);
      flex-grow: 1;
      margin-left: var(--content-padding);
      border: 1px solid var(--Gray300);
      text-align: center;
      color: var(--Gray600);
      border-radius: 8px;

      .coming-soon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
