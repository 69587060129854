.add-reward-option-modal {
  min-height: 280px;
  width: 500px;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  .modal-contents {
    flex: 1;
    flex-grow: 1;
    padding: 45px 32px 36px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .headline {
      width: 100%;
      margin-bottom: 24px;
      color: var(--Gray900);
      font-size: 32px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }

    .choices-label {
      margin-top: 24px;
    }

    .choices-container {
      max-height: 300px;
      overflow-y: scroll;

      .choice-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;

        .remove-choice {
          padding: 8px;
          margin-left: 48px;
          cursor: pointer;
          user-select: none;

          &:hover {
            opacity: 0.8;
          }
          &:active {
            opacity: 0.5;
          }
        }
      }
    }

    .add-choice-row {
      width: 100%;
      margin-top: 16px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .add-choice {
        margin-right: -8px;
        padding: 8px;
        color: var(--NickelPrimaryLight);
        font-size: 14px;
        cursor: pointer;
        user-select: none;

        &:hover {
          opacity: 0.8;
        }
        &:active {
          opacity: 0.5;
        }
      }
    }
  }

  .bottom-buttons {
    padding: 14px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--Gray200);
  }
}
