.team-members-table-container {
  --container-height: calc(
    var(--content-inner-height) - 2 * var(--content-padding)
  );

  height: var(--container-height);
  display: flex;
  flex-direction: column;

  .team-members-table-title {
    flex-shrink: 0;
    height: calc(2 * var(--content-padding));
    display: flex;
    font-size: 28px;
  }

  .ka {
    --table-height: calc(var(--container-height) - 2 * var(--content-padding));
    --header-row-height: 48px;

    height: var(--table-height);

    .ka-tbody {
      max-height: calc(var(--table-height) - var(--header-row-height));

      > .ka-tr:nth-child(even) {
        .profile-picture,
        .profile-picture-placeholder {
          background-color: var(--Gray300);
        }
      }
    }
  }

  .social-cell {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .social-cell-image {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 25px;
    }

    .social-cell-handle {
      margin-left: 8px;
    }
  }

  .social-cell-placeholder {
    height: 40px;
  }

  .ellipsis-cell {
    min-width: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
