.title-slide {
  // gets positioning classes from .slide in NewCampaignModal.scss

  display: flex;
  flex-direction: column;

  .inputs {
    .reward-picture-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .double-input-container {
      width: 100%;
      display: flex;
      justify-content: space-between;

      > * {
        width: calc(50% - 8px);
      }
    }
  }
}
