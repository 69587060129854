// Inherits variables from ./Statistics.scss
.campaign-health-table {
  --title-padding-bottom: var(--dashboard-gap);
  --title-line-height-base: 24;
  --title-line-height-pct: calc(var(--title-line-height-base) / var(--dashboard-pixel-base));
  --title-line-height: calc(var(--dashboard-width) * var(--title-line-height-pct));

  --table-header-height-base: 52;
  --table-header-height-pct: calc(var(--table-header-height-base) / var(--dashboard-pixel-base));
  --table-header-height: calc(var(--dashboard-width) * var(--table-header-height-pct));

  --table-row-height-base: 42;
  --table-row-height-pct: calc(var(--table-row-height-base) / var(--dashboard-pixel-base));
  --table-row-height: calc(var(--dashboard-width) * var(--table-row-height-pct));

  --health-height-pct: calc(var(--health-height-pixel-base) / var(--dashboard-pixel-base));
  --health-height: calc(var(--dashboard-width) * var(--health-height-pct));

  --font-size-base: 14;
  --font-size-pct: calc(var(--font-size-base) / var(--dashboard-pixel-base));
  --font-size: calc(var(--dashboard-width) * var(--font-size-pct));

  --line-height-base: 24;
  --line-height-pct: calc(var(--line-height-base) / var(--dashboard-pixel-base));
  --line-height: calc(var(--dashboard-width) * var(--line-height-pct));

  --cell-padding-base: 16;
  --cell-padding-pct: calc(var(--cell-padding-base) / var(--dashboard-pixel-base));
  --cell-padding: calc(var(--dashboard-width) * var(--cell-padding-pct));

  --grid-template: 1fr / 1.5fr 0.65fr 0.85fr repeat(3, 1fr) 1.25fr;

  width: 100%;
  height: calc(var(--health-height));
  padding: calc(var(--dashboard-gap) * 2 / 3) var(--dashboard-gap);
  box-sizing: border-box;
  background-color: var(--White);
  color: var(--Gray900);
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-box-shadow);
  overflow: hidden;

  .campaign-health-title {
    padding-bottom: var(--title-padding-bottom);

    --font-size-base: 16;
    --font-size-pct: calc(var(--font-size-base) / var(--dashboard-pixel-base));
    --font-size: calc(var(--dashboard-width) * var(--font-size-pct));
    font-size: var(--font-size);

    line-height: var(--title-line-height);
  }

  .table-header {
    height: var(--table-header-height);
    display: grid;
    grid-template: var(--grid-template);

    .header-item {
      padding-left: var(--cell-padding);
      display: flex;
      align-items: center;
      background-color: var(--Gray100);
      font-size: var(--font-size);
      line-height: var(--line-height);
      font-weight: 600;

      .header-icon {
        padding-left: calc(var(--font-size) / 2);
        height: var(--font-size);
        width: var(--font-size);
      }
    }
  }

  .table-body-wrapper {
    --table-height: calc(100% - var(--title-line-height) - var(--title-padding-bottom));

    height: var(--table-height);

    .table-body {
      max-height: var(--table-height);
      display: grid;
      grid-template: var(--grid-template);
      overflow-y: scroll;
    }

    .campaign-row-item {
      flex-shrink: 0;
      height: var(--table-row-height);
      padding-left: var(--cell-padding);
      display: flex;
      align-items: center;
      font-size: var(--font-size);
      line-height: var(--line-height);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &.title &:nth-child(3n) {
        padding-right: calc(var(--dashboard-gap) * 3);
      }

      &:nth-child(14n-6),
      &:nth-child(14n-5),
      &:nth-child(14n-4),
      &:nth-child(14n-3),
      &:nth-child(14n-2),
      &:nth-child(14n-1),
      &:nth-child(14n) {
        background-color: var(--Gray100);
      }

      .status-circle {
        --size-base: 8;
        --size-pct: calc(var(--size-base) / var(--dashboard-pixel-base));
        --size: calc(var(--dashboard-width) * var(--size-pct));

        height: var(--size);
        width: var(--size);
        margin-right: calc(var(--size) / 2);
        border-radius: 100px;

        &.Live {
          background-color: var(--Green);
        }
        &.Ended {
          background-color: var(--Red);
        }
        &.Draft {
          background-color: var(--Gray400);
        }
      }
    }
  }
}
