.ka {
  // margin-bottom: var(--content-padding); Do we need this?
  height: 100%;
  color: var(--Gray900);
  border-radius: 8px;
  box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 0px 3px 0 rgba(0, 0, 0, 0.12),
    0 2px 16px 0 rgba(0, 0, 0, 0.08);
  overflow-y: scroll;

  .ka-table {
    .ka-thead {
      background-color: var(--Gray100);

      .ka-thead-cell {
        padding-right: 0px;
        color: var(--Gray900);

        &:last-of-type {
          padding-right: 20px;
        }
      }
    }

    .ka-tbody > .ka-tr:nth-child(even) {
      background-color: var(--Gray100);
    }
    .ka-row {
      cursor: pointer;

      .ka-cell {
        padding-right: 0px;

        &:last-of-type {
          padding-right: 20px;
        }
      }
    }
  }
  .ka-group-row {
    background-color: var(--Gray100);
  }
}
