.auth-title {
  max-width: 50vw;
  margin: 0px;
  font-size: 26px;
  line-height: 40px;
  font-weight: 700;
  text-align: center;

  + .auth-title {
    margin-bottom: 32px;
  }
}

.auth-subtitle {
  text-align: center;
}

.auth-square {
  padding: 40px;
  max-width: 400px;
  min-height: 350px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

  .tenant-select-container {
    width: 322px;
    margin-bottom: 32px;
  }

  .tenant-container {
    width: 322px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;

    .tenant-emblem {
      margin-right: 12px;
      img {
        height: 36px;
        width: 36px;
      }
    }
    .tenant-name {
      font-size: 14px;
      line-height: 40px;
      font-weight: 400;
    }
  }

  .tenant-image {
    width: 100px;
    margin-bottom: 10px;
  }

  .subtitle {
    width: 100px;
  }

  .auth-logo {
    width: 70%;
  }

  .auth-inputs {
    width: 322px;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .auth-button {
    height: 40px;
    width: 322px;
    margin-top: 30px;
    background-color: #2487ff;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;
    font-weight: 700;
    cursor: pointer;

    &:active {
      opacity: 0.9;
    }
  }

  .nickel-input-error + .auth-button {
    margin-top: 8px;
  }

  .forgot-password {
    margin-top: 10px;
    font-size: 14px;
    line-height: 22px;
    opacity: 0.3;
    cursor: pointer;
    user-select: none;

    &:active {
      opacity: 0.2;
    }
  }

  .contact-title {
    font-size: 14px;
    margin-top: 25px;
  }

  .contact-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    text-align: center;
    font-size: 14px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .contact-label {
    font-weight: 600;
  }
}
