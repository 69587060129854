.membership-trend {
  --Green: #29c4b2;
  --Purple: #6f5ed3;

  height: 100%;
  width: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: var(--White);
  border-radius: 8px;
  box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 16px 0 rgba(0, 0, 0, 0.08);
  overflow: hidden;

  .chart-title {
    flex: 0;
    margin: 0 0 24px;
    font-size: 14px;
    line-height: 24px;
  }

  .chart-wrapper {
    max-height: calc(100% - 24px - 24px);
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .spinner-container {
      width: 100%;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
