.emblem-fade {
  position: absolute;
  width: 90%;
  top: -10%;
  left: -10%;
  opacity: 0.1;
  pointer-events: none;
}

.media-col {
  flex-grow: 1;
  position: relative;
  height: 400px;

  .backer,
  .image {
    position: absolute;
    height: calc(100% - 24px);
    width: calc(100% - 24px);
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.28);
  }
  .backer {
    top: 0;
    left: 0;
    background-color: var(--primary-color);
  }
  .image {
    top: 24px;
    left: 24px;
    background-color: #000;
    background-position: center;
    background-size: cover;
  }
}
