@import "node_modules/ka-table/style.scss";

.users-container {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: var(--content-padding) var(--content-padding) 0px;

  .user-profile-container {
    height: 60px;
    display: flex;
    align-items: center;
    .user-profile-picture {
    }
    .user-name {
      width: 128px;
    }
    .user-pending {
      box-sizing: border-box;
      margin-left: 16px;
      padding: 0px 8px;
      border-radius: 10px;
      background-color: var(--secondary-color);
      color: var(--White);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .user-resend {
      margin-left: 16px;
      font-size: 14px;
      font-variant: small-caps;
      color: var(--NickelPrimaryLight);
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
