.app-theme-container {
  height: var(--content-inner-height);
  width: 100%;
  padding: var(--content-padding) var(--content-padding) 0px;
  display: flex;
  overflow: scroll;

  .theme-section {
    width: 500px;
    &:first-child {
      margin-right: 48px;
    }

    .theme-section-header {
      margin-bottom: 20px;
      color: var(--Gray900);
      font-size: 30px;
      font-weight: 500;
    }

    .theme-section-items {
      .theme-item {
        margin-bottom: 48px;
        display: flex;

        &:last-child {
          padding-bottom: calc(2 * var(--content-padding));
        }

        .theme-item-titles {
          width: 300px;
          margin-right: 56px;

          .theme-item-title {
            margin-bottom: 6px;
            font-size: 22px;
            color: var(--Gray900);
          }

          .theme-item-subtitle,
          .theme-item-note {
            font-size: 16px;
            line-height: 1.5;
            color: var(--Gray500);
          }
          .theme-item-subtitle {
            margin-bottom: 16px;
          }
          .theme-item-note {
          }
        }

        .theme-item-content {
          margin-top: 6px;
        }
      }
    }
  }
}
