.input-component-container {
  width: 100%;
  position: relative;
  box-sizing: border-box;

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: not-allowed;
  }

  .input-container {
    position: relative;
    display: flex;
    justify-content: center;
    // z-index: 1;

    input {
      width: 100%;
      height: 40px;
      padding: 8px 0px 8px 16px;
      border-radius: 8px;
      box-sizing: border-box;
      border: solid 1px var(--Gray400);
      background-color: var(--White);
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: var(--Gray900);

      &::placeholder {
        color: #222;
        opacity: 0.2;
        font-family: Montserrat;
      }
      &:focus {
        outline: none;
      }
    }

    .icon-container {
      width: 40px;
      height: 40px;
      position: absolute;
      right: 0;
      background-color: var(--Gray200);
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border: solid 1px var(--Gray400);
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      // z-index: 1;

      > * {
        opacity: 0.3;
        color: var(--Black);
      }
    }

    .clearable-container {
      width: 40px;
      height: 40px;
      position: absolute;
      right: 0;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      // z-index: 1;

      > * {
        opacity: 0.3;
        color: var(--Gray700);
      }
    }
  }

  .character-counter-container {
    position: absolute;
    bottom: -16px;
    right: 16px;
    padding-top: 2px;
  }
}
