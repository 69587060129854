// Inherits variables from ./Statistics.scss
.latest-donations {
  --title-padding-bottom: var(--dashboard-gap);
  --title-line-height-base: 24;
  --title-line-height-pct: calc(var(--title-line-height-base) / var(--right-col-pixel-base));
  --title-line-height: calc(var(--right-col-width) * var(--title-line-height-pct));

  --table-header-height-base: 52;
  --table-header-height-pct: calc(var(--table-header-height-base) / var(--right-col-pixel-base));
  --table-header-height: calc(var(--right-col-width) * var(--table-header-height-pct));

  color: var(--Gray900);

  --font-size-base: 14;
  --font-size-pct: calc(var(--font-size-base) / var(--right-col-pixel-base));
  --font-size: calc(var(--right-col-width) * var(--font-size-pct));

  --line-height-base: 24;
  --line-height-pct: calc(var(--line-height-base) / var(--right-col-pixel-base));
  --line-height: calc(var(--right-col-width) * var(--line-height-pct));

  --cell-padding-base: 16;
  --cell-padding-pct: calc(var(--cell-padding-base) / var(--right-col-pixel-base));
  --cell-padding: calc(var(--right-col-width) * var(--cell-padding-pct));

  --amount-col-width-base: 150;
  --amount-col-width-pct: calc(var(--amount-col-width-base) / var(--right-col-pixel-base));
  --amount-col-width: calc(var(--right-col-width) * var(--amount-col-width-pct));

  --grid-template-columns: 1fr 1fr var(--amount-col-width);
  --grid-template: 1fr / var(--grid-template-columns);

  width: 100%;
  height: var(--right-col-width);
  margin-bottom: var(--dashboard-gap);
  padding: calc(var(--dashboard-gap) * 2 / 3) var(--dashboard-gap);
  box-sizing: border-box;
  background-color: var(--White);
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-box-shadow);
  overflow: hidden;

  .latest-donations-title {
    padding-bottom: var(--title-padding-bottom);

    --font-size-base: 16;
    --font-size-pct: calc(var(--font-size-base) / var(--right-col-pixel-base));
    --font-size: calc(var(--right-col-width) * var(--font-size-pct));
    font-size: var(--font-size);

    line-height: var(--title-line-height);
  }

  .table-header {
    height: var(--table-header-height);
    display: grid;
    grid-template: var(--grid-template);

    .header-item {
      padding-left: var(--cell-padding);
      display: flex;
      align-items: center;
      background-color: var(--Gray100);
      font-size: var(--font-size);
      line-height: var(--line-height);
      font-weight: 600;

      .header-icon {
        padding-left: calc(var(--font-size) / 2);
        height: var(--font-size);
        width: var(--font-size);
      }
    }
  }

  .table-body-wrapper {
    --table-height: calc(100% - var(--title-line-height) - var(--title-padding-bottom));

    height: var(--table-height);

    .table-body {
      max-height: var(--table-height);
      width: 100%;
      display: grid;
      grid-template-columns: var(--grid-template-columns);
      grid-template-rows: repeat(auto-fit, 1fr) auto;
      overflow-y: scroll;
    }

    .donation-row-item {
      --vertical-padding-base: 10;
      --vertical-padding-pct: calc(var(--vertical-padding-base) / var(--right-col-pixel-base));
      --vertical-padding: calc(var(--right-col-width) * var(--vertical-padding-pct));

      max-height: 32px;
      padding: var(--vertical-padding) var(--cell-padding);

      font-size: var(--font-size);
      line-height: var(--line-height);

      &.title &:nth-child(3n) {
        padding-right: calc(var(--dashboard-gap) * 3);
      }

      &:nth-child(6n-2),
      &:nth-child(6n-1),
      &:nth-child(6n) {
        background-color: var(--Gray100);
      }

      &.filler {
        background-color: #fff;
      }
    }
  }
}
