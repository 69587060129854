.level-detail {
  --detail-width: 300px;

  height: var(--content-inner-height);
  width: 100%;
  padding: var(--content-padding) var(--content-padding) 0px;
  box-sizing: border-box;
  display: grid;
  grid-template: 1fr / var(--detail-width) 1fr;
  grid-gap: 24px;
  overflow: auto;

  .level-details {
    position: relative;
    z-index: 1;
    margin-bottom: 50px;
    padding: 24px;
    box-sizing: border-box;
    color: var(--Gray900);
    background-color: var(--White);
    border-radius: 8px;
    box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12),
      0 2px 16px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;

    .level-info-row {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      .info-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title-line {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 12px;
        }
        .detail-line {
          color: var(--secondary-color);
          font-size: 24px;
          font-weight: 600;
        }
      }

      .divider {
        align-self: stretch;
        width: 1px;
        background-color: var(--Gray200);
      }
    }

    .info-row {
      position: relative;
      margin: 0px -24px;
      margin-bottom: 12px;
      padding: 0px 24px;
      border-top: 1px solid var(--Gray200);
      padding-top: 12px;

      &:hover {
        .edit-value {
          opacity: 1;
          cursor: pointer;
        }
      }

      .info-title {
        margin-bottom: 12px;
        color: var(--Gray400);
        font-size: 12px;
      }

      .info-text {
        font-size: 20px;
        font-weight: 500;
      }
      .edit-value {
        position: absolute;
        right: 24px;
        top: 50%;
        color: var(--NickelPrimaryLight);
        font-size: 14px;
        opacity: 0;
      }
    }
  }

  .input-container {
    display: flex;
    align-items: center;

    .cancel-value {
      color: var(--Red);
      font-size: 20px;
      opacity: 1;
      cursor: pointer;
      margin: 0px 8px;
    }

    .save-value {
      color: var(--Green);
      font-size: 20px;
      opacity: 1;
      cursor: pointer;
    }
  }

  .level-table-wrapper {
    --content-width: calc(
      var(--content-inner-width) - var(--detail-width) - var(--content-padding)
    );

    background-color: blue;

    width: var(--content-width);
    min-width: 760px;
    display: flex;
    flex-direction: column;
  }
}
