.address-slide {
  // gets positioning classes from .slide in NewCampaignModal.scss

  .inputs-shared-row {
    display: flex;
    justify-content: space-between;

    > div {
      flex: 1;
      padding-right: 20px;

      &:first-of-type {
        flex: 2;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }
  }
}
