.color-picker-button {
  position: relative;
  height: 48px;
  padding: 12px 16px 12px 0px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--Gray200);
  border: solid 1px var(--Gray300);
  border-radius: 8px;
  cursor: pointer;
  user-select: none;

  .color-preview {
    height: 48px;
    width: 48px;
    margin-right: 16px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .button-text {
    color: var(--Gray500);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-align: center;
    text-transform: uppercase;
  }

  .picker {
    position: absolute;
    z-index: 1;
    top: calc(100% + 2px);
    left: 24px;
    transform: translate(-50%, 0);
    margin-bottom: 12px;
    border-radius: 6px;
    box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 16px 0 rgba(0, 0, 0, 0.08);
  }
}
