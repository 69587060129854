.protected-content-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparentize(#fafafa, 0.3);
  backdrop-filter: blur(8px);

  > :first-child {
    max-width: 450px;
    margin-bottom: var(--content-padding);
  }
}
