.number-slide {
  display: flex;
  flex-direction: column;

  .inputs {
    > * {
      width: 100%;
    }
  }
}
