.plan-step-details-container {
  min-width: 374px;
  height: 72px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;
  border-radius: 12px;
  background-color: var(--Gray100);
  cursor: pointer;

  &:last-child {
    margin-bottom: 0px;
  }

  .who-container {
    min-width: 0;
    display: flex;
    margin-left: 16px;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: var(--Gray900);
    .who-label {
      min-width: 46px;
      height: 24px;
      font-weight: normal;
    }
    .who-text {
      // max-width: 276px;
      height: 24px;
      margin: 0px 16px;
      font-weight: 600;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .when-container {
    display: flex;
    margin-left: 16px;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: var(--Gray900);
    .when-label {
      min-width: 46px;
      height: 24px;
      font-weight: normal;
    }
    .when-text {
      max-width: 276px;
      height: 24px;
      margin-left: 16px;
      font-weight: 600;
    }
  }
  .plan-step-delete {
    height: 20px;
    width: 20px;
    top: -10px;
    right: -10px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Gray500);
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 24px;
  }
}
