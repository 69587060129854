.club-member-detail {
  --detail-width: 300px;

  height: var(--content-inner-height);
  width: 100%;
  padding: var(--content-padding) var(--content-padding) 0px;
  box-sizing: border-box;
  display: grid;
  grid-template: 1fr / var(--detail-width) 1fr;
  grid-gap: 24px;
  overflow: scroll;

  .club-member-pages-wrapper {
    --tab-bar-height: 71px;
    --content-width: calc(var(--content-inner-width) - var(--detail-width) - var(--content-padding));

    width: var(--content-width);
    min-width: 760px;
    display: flex;
    flex-direction: column;

    .club-member-tab-bar-wrapper {
      flex: 0;
      width: 100%;
    }

    .tab-detail {
      flex-grow: 1;
      width: 100%;
    }
  }
}

.club-member-loading-container {
  height: var(--content-inner-height);
  width: 100%;
  padding: var(--content-padding) var(--content-padding) 0px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
