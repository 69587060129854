.team-icon-modal {
  min-height: 380px;
  width: 500px;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  .modal-contents {
    flex: 1;
    flex-grow: 1;
    padding: 45px 32px 36px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .headline {
      width: 100%;
      margin-bottom: 24px;
      color: var(--Gray900);
      font-size: 32px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }

    .team-icons-container {
      width: 100%;
      display: grid;
      grid-template-rows: 64px;
      grid-template-columns: repeat(auto-fit, minmax(64px, 1fr));
      grid-gap: 8px;

      .icon-square {
        height: 64px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &.selected {
          border: 3px solid var(--primary-color);
          border-radius: 2px;
        }
        &:hover {
          opacity: 0.7;
        }
        &:active {
          opacity: 0.4;
        }
      }
    }
  }

  .bottom-buttons {
    padding: 14px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--Gray200);
  }
}
