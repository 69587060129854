.details {
  --detail-width: 300px;

  height: var(--content-inner-height);
  width: 100%;
  padding: var(--content-padding) var(--content-padding) 0px;
  box-sizing: border-box;
  display: grid;
  grid-template: 1fr / var(--detail-width) 1fr;
  grid-gap: 24px;
  overflow: scroll;
}

.pageWrapper {
  --tab-bar-height: 71px;
  --content-width: calc(var(--content-inner-width) - var(--detail-width) - var(--content-padding));

  width: var(--content-width);
  min-width: 760px;
  display: flex;
  flex-direction: column;
}

.tabBarWrapper {
  flex: 0;
  width: 100%;
}

.tabDetail {
  flex-grow: 1;
  width: 100%;
}