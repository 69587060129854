.manage-title {
  // max-width: 600px;
  height: var(--content-inner-height);
  padding: var(--content-padding) var(--content-padding) 0px;
  overflow: scroll;
  box-sizing: border-box;

  .inputs-container {
    max-width: 600px;

    > * {
      margin-bottom: 32px;
    }

    textarea {
      height: 250px;
    }

    .double-input-container {
      width: 100%;
      display: flex;
      justify-content: space-between;

      > * {
        width: calc(50% - 8px);
      }
    }

    .point-multiplier-container {
      width: 100%;
      display: flex;
      flex-direction: column;

      .point-multiplier-explanation {
        font-size: 12px;
        margin-top: 4px;
        margin-left: 8px;
      }
    }
  }
}
