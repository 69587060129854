.modal {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 30px 4px rgba(0, 0, 0, 0.04);
  overflow: hidden;

  .slides-container {
    position: relative;
    width: 581px;
    height: 632px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    overflow-y: scroll;

    .slide-content {
      position: relative;
      height: calc(100% - 68px);

      .slide {
        padding: 32px;
        position: absolute;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        overflow-y: scroll;

        .headline {
          max-height: 100px;
          width: 517px;
          font-family: Montserrat;
          margin-bottom: 24px;
          font-size: 32px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--Gray900);
        }

        .inputs {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          > * {
            margin-bottom: 32px;
          }
        }
      }
    }
  }
}
