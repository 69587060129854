@import "node_modules/ka-table/style.scss";

.campaign-settings-container {
  height: 100%;
  padding: var(--content-padding) var(--content-padding) 0px;
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 32px;
  }

  .quid-title,
  .point-title {
    margin-bottom: 20px;
    font-size: 22px;
    color: var(--Gray900);
  }

  .point-multiplier-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .point-multiplier-explanation {
      font-size: 12px;
      margin-top: 4px;
      margin-left: 8px;
    }
  }
}
