.verification-slide {
  .details-container {
    padding: 0px 12px;
  }
  .info-line {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--Gray900);
  }
  // gets positioning classes from .slide in NewCampaignModal.scss
}
