.media-container {
  height: 100%;
  width: 100%;
  // overflow: hidden;

  .upload-box {
    position: relative;
    width: 100%;
    padding: 36px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-radius: 8px;
    border: solid 1px var(--Gray400);
    background-color: var(--Gray100);
    cursor: pointer;

    &.circular {
      height: 200px;
      width: 200px;
      border-radius: 100px;
    }

    .overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      display: flex;
      padding: 24px;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.8);
      color: var(--Gray900);

      &:hover {
        color: var(--secondary-color);
        background-color: rgba(255, 255, 255, 0.9);
      }
    }

    .upload-placeholder-image {
      height: 48px;
      width: 64px;
      margin-bottom: 4px;
    }

    .text-container {
      height: 28px;
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: var(--Gray500);
      .upload-instructions {
        margin-bottom: 4px;
      }
    }
  }

  .upload-box.drag {
    position: relative;
    /* more styles */
  }

  .upload-box.drag:before {
    position: absolute;
    content: "Drop file to upload";
    top: 50%;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);
    /* colors, etc. */
  }
}
