.targets-slide {
  // gets positioning classes from .slide in NewCampaignModal.scss

  display: flex;
  flex-direction: column;

  .double-input-container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    > * {
      width: calc(50% - 8px);
    }
  }
}
