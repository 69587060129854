.progress-container {
  width: 292px;
  height: 632px;
  background-color: var(--Gray100);
  padding: 24px;
  box-sizing: border-box;

  .progress-title {
    width: 244px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--Gray900);
  }

  .progress-marker-container {
    margin-top: 22px;

    .progress-marker {
      margin-bottom: 62px;
      display: flex;

      .marker {
        width: 48px;
        height: 48px;
        background-color: var(--primary-color);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        border-radius: 24px;
        color: var(--White);
      }
      .title-description-container {
        margin-left: 16px;
        .title {
          width: 182px;
          height: 27px;
          font-family: Montserrat;
          font-size: 22px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--Gray900);
        }
        .description {
          width: 182px;
          height: 14px;
          font-family: Montserrat;
          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--Gray500);
        }
      }
    }
  }
}
