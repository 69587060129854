.contributions-content {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .contributions-table {
    width: 22%;
    margin-bottom: 20px;
    box-sizing: border-box;
    background-color: var(--White);
    border-radius: 8px;
    box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 16px 0 rgba(0, 0, 0, 0.08);
    overflow: scroll;

    .contributions-table-title {
      padding: 0 12px;
    }
  }

  .contributions-wrapper {
    width: 75%;
    min-height: 400px;
    margin-bottom: 20px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: var(--White);
    border-radius: 8px;
    box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 16px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;

    .chart-title {
      flex: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
    }

    .chart-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &--column {
        flex-direction: column;
      }
    }
  }
}
