.team-member-modal {
  height: 420px;
  width: 580px;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  .modal-contents {
    flex: 1;
    flex-grow: 1;
    padding: 45px 32px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    .headline {
      width: 100%;
      color: var(--Gray900);
      font-size: 32px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }

    .add-member {
      margin-top: 12px;
      font-size: 12px;
      color: var(--NickelPrimaryLight);
      cursor: pointer;
      margin-bottom: 16px;
    }
    .team-member-container {
      width: 100%;
      display: flex;
      justify-content: space-around;
      margin-bottom: 16px !important;

      .input-fields {
        width: calc(50% - 30px);
      }

      .delete-team-member {
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--Gray500);
        flex-shrink: 1;
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }

  .bottom-buttons {
    padding: 14px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--Gray200);

    .right-buttons {
      display: flex;
    }
  }
}
