.hosting-container {
  height: var(--content-inner-height);
  width: 100%;
  padding: var(--content-padding) var(--content-padding) 0px;
  overflow: scroll;

  .hosting-section {
    margin-bottom: 48px;
  }

  .hosting-title {
    margin-bottom: 20px;
    font-size: 22px;
    color: var(--Gray900);
  }

  .input-button-row {
    margin-right: 192px;
    display: grid;
    grid-template: 1fr / 2fr 1fr;
    grid-gap: 48px;
  }

  .verified-text-wrapper {
    margin-top: 13px;
    display: flex;
    justify-content: center;
    color: var(--Green);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;

    .verified-text-contents {
      display: flex;
      align-items: center;
      height: 40px;
      margin-top: 12px;
      padding: 10px 16px;
    }

    .verified-text {
      margin-left: 12px;
    }
  }
}
