.benefits {
  width: var(--content-width);
  height: auto;
  padding: var(--content-padding);
  padding-top: 0;

  .benefits-title-section {
    display: flex;
    align-items: center;
  }

  .benefits-title {
    margin-right: 15px;
    font-size: 20px;
    letter-spacing: 'initial';
    text-transform: none;
    font-weight: 500;
  }

  .benefit-cards {
    display: flex;
    overflow-x: scroll;
    padding: 7px 0;
  }

  .benefit-card {
    min-width: 240px;
    margin-right: 20px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--White);
    border-radius: 6px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .benefit-label {
    color: var(--Gray500);
    font-size: 12px;
  }

  .benefits-next-level {
    font-size: 14px;
    color: var(--Yellow);
    font-weight: 500;
  }

  .benefit-value {
    font-weight: 500;
    font-size: 14px;
  }

  .team-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    margin: 0;
    background-color: var(--primary-color);
    border-radius: 50%;
  }

  .sport-title {
    margin: 5px 0;
    font-size: 20px;
    font-weight: 500;
  }

  .tickets-count {
    font-size: 56px;
    font-weight: 500;
  }

  .tickets-count-label {
    font-size: 14px;
    color: var(--Gray500);
  }

  .benefit-box {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .benefit-details {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 15px;
  }

  .benefit-extras {
    width: 100%;
    margin: 22px 0;
  }
  
  .benefit-extras-list {
    list-style: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  .benefits-select {
    width: 300px;
    margin: -8px 15px 0 0;
  }
}