@import "node_modules/ka-table/style.scss";

.campaign-extras {
  height: 100%;
  width: 100%;
  padding: var(--content-padding) var(--content-padding) 0px;
  box-sizing: border-box;
  overflow: scroll;

  .extras-section {
    margin-bottom: 48px;
  }

  .extras-title {
    margin-bottom: 20px;
    font-size: 22px;
    color: var(--Gray900);
  }
}
