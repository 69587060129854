.integrations-data-sources {
  width: 100%;
  height: var(--content-inner-height);
  padding: var(--content-padding) var(--content-padding) 0px;
  overflow: scroll;
  box-sizing: border-box;

  .target-squares {
    display: grid;
    grid-template: repeat(auto-fit, 180px) / repeat(auto-fit, minmax(330px, 1fr));
    grid-gap: var(--content-padding);
    grid-gap: var(--content-padding);

    .target-square {
      height: 180px;
      max-width: 450px;
      display: flex;
      flex-direction: column;
      border-radius: 12px;
      box-sizing: border-box;
      overflow: hidden;
      box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 16px 0 rgba(0, 0, 0, 0.08);
      cursor: pointer;
      user-select: none;

      &:hover {
        opacity: 0.9;
      }
      &:active {
        opacity: 0.7;
      }

      .titles {
        padding: 10px 14px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .info-title {
          font-size: 20px;
          font-weight: 500;
          color: var(--Gray900);
        }
        .info-subtitle {
          padding-left: 2px;
          font-size: 10px;
          font-weight: 300;
          color: #888;
        }
      }

      .info-wrapper {
        --vertical-padding-width-ratio: 12 / var(--full-width);
        --horiz-padding-width-ratio: 16 / var(--full-width);

        flex-grow: 1;
        width: 100%;
        padding: 10px 14px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;

        .metrics {
          width: 100%;
          box-sizing: border-box;
          margin-bottom: 10px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .metric-wrapper {
            &.donated {
              flex: 1 1 0;
            }

            &.donors {
              flex: 1 1 0;
            }

            &.days-left {
              flex: 1 1 0;
            }

            .metric {
              font-size: 20px;
              font-weight: 500;
              color: var(--Gray900);
            }

            .label {
              font-size: 10px;
              font-weight: 300;
              color: var(--Gray500);
            }
          }
        }

        .progress-bar {
          height: 10px;
          width: 100%;
          background-color: var(--Gray200);
          border-radius: 5px;
          display: flex;
          align-items: center;

          .progress {
            height: 10px;
            width: 100%;
            background-color: var(--Gray200);
            border-radius: 5px;
            background-color: var(--secondary-color);
          }
        }
      }
    }
  }
}
