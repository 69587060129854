.perks {
  height: calc(var(--content-inner-height) - var(--content-padding));
  padding: var(--content-padding) var(--content-padding) 0px;
  overflow: scroll;

  .edit-perk-cards {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template: repeat(auto-fit, 350px) / repeat(auto-fit, 372px);
    grid-gap: var(--content-padding);
    box-sizing: border-box;
  }

  .zero-state {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .zero-state-text {
      max-width: 65%;
      margin: 48px 0px;
      color: var(--Gray300);
      font-size: 36px;
      text-align: center;
    }
  }
}
