.manage-schedule {
  // max-width: 600px;
  height: var(--content-inner-height);
  margin-bottom: var(--content-padding);
  padding: var(--content-padding) var(--content-padding) 0px;
  overflow: scroll;
  box-sizing: border-box;

  .timeline {
    display: flex;
    padding-bottom: var(--content-padding);

    .timeline-dashes {
      height: 100%;
      margin-left: 32px;
      border-left: dashed 2px var(--Gray400);
    }

    .steps-container {
      .date-and-step-container {
        margin-bottom: 24px;
        display: flex;
        align-items: center;

        &:first-child {
          margin-top: 12px;
        }
        &:last-child {
          margin-bottom: 12px;
        }

        .date-details {
          width: 48px;
          margin: 0px 36px 0px -25px;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          color: var(--Gray900);
          background-color: var(--Gray50);
          z-index: 1;

          .date-day {
            width: 100%;
            height: 24px;
            font-size: 22px;
            font-weight: 500;
            line-height: normal;
          }
          .date-month {
            width: 100%;
            height: 24px;
            font-size: 14px;
            font-weight: normal;
            line-height: 1.71;
          }
        }
      }
    }
  }

  .zero-state {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .zero-state-text {
      max-width: 65%;
      margin: 48px 0px;
      color: var(--Gray300);
      font-size: 36px;
      text-align: center;
    }
  }
}
