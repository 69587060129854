.club-hub-container {
  height: var(--content-inner-height);
  width: 100%;
  padding: var(--content-padding);
  overflow: scroll;
  box-sizing: border-box;

  .theme-item {
    margin-bottom: 48px;
    display: flex;

    .theme-item-titles {
      width: 300px;
      margin-right: 56px;

      .theme-item-title {
        margin-bottom: 6px;
        font-size: 22px;
        color: var(--Gray900);
      }

      .theme-item-subtitle {
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 1.5;
        color: var(--Gray500);
      }
    }

    .theme-item-content {
      &.image {
        min-width: 240px;
      }

      &--extended {
        min-width: 350px;
      }
    }

    .theme-datepicker {
      margin-bottom: 10px;
    }
  }
}
