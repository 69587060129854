.manage-campaigns {
  width: 100%;
  height: var(--content-inner-height);
  padding: var(--content-padding) var(--content-padding) 0px;
  overflow: scroll;
  box-sizing: border-box;

  .campaign-squares-title {
    height: 18px;
    margin-top: 48px;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  .campaign-squares {
    display: grid;
    grid-template: repeat(auto-fit, 300px) / repeat(
        auto-fit,
        minmax(330px, 1fr)
      );
    grid-gap: var(--content-padding);
    grid-gap: var(--content-padding);
  }
}
