.rewards-slide {
  // gets positioning classes from .slide in NewCampaignModal.scss
  display: flex;
  flex-direction: column;

  .inputs {
    .reward-quantity-available {
      align-self: flex-end;
      margin-top: -28px;
      margin-bottom: 0;
      margin-right: 4px;
      color: var(--Gray500);
      font-size: 11px;
    }
  }
}
