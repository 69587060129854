.app-achievement {
  height: var(--content-inner-height);
  width: 100%;
  padding: var(--content-padding) var(--content-padding) 0px;
  box-sizing: border-box;
  overflow: scroll;

  .limit-width {
    max-width: 480px;

    .label {
      margin-top: 24px;
    }

    > :first-child .label {
      margin-top: 0px;
    }
  }
}
