.campaign-square {
  height: 300px;
  max-width: 450px;
  border-radius: 12px;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 16px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.7;
  }

  .image-wrapper {
    height: 50%;
    width: 100%;
    box-sizing: border-box;
    background-color: rgb(245, 245, 245);
    overflow: hidden;

    .image {
      height: 100%;
      width: 100%;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
      background-size: cover;
      background-position: top center;
    }
  }

  .info-wrapper {
    --vertical-padding-width-ratio: 12 / var(--full-width);
    --horiz-padding-width-ratio: 16 / var(--full-width);

    width: 100%;
    padding: 10px 14px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .titles {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .info-title {
        font-size: 20px;
        font-weight: 500;
        color: var(--Gray900);
      }
      .info-subtitle {
        font-size: 10px;
        font-weight: 300;
        color: #888;
      }
    }

    .metrics {
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .metric-wrapper {
        &.donated {
          flex: 1 1 0;
        }

        &.donors {
          flex: 1 1 0;
        }

        &.days-left {
          flex: 1 1 0;
        }

        .metric {
          font-size: 20px;
          font-weight: 500;
          color: var(--Gray900);
        }

        .label {
          font-size: 10px;
          font-weight: 300;
          color: var(--Gray500);
        }
      }
    }

    .progress-bar {
      height: 10px;
      width: 100%;
      background-color: var(--Gray200);
      border-radius: 5px;
      display: flex;
      align-items: center;

      .progress {
        height: 10px;
        width: 100%;
        background-color: var(--Gray200);
        border-radius: 5px;
        background-color: var(--secondary-color);
      }
    }
  }
}
