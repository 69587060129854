.reward-item-detail {
  height: var(--content-inner-height);
  width: 100%;
  padding: var(--content-padding) var(--content-padding) 0px;
  box-sizing: border-box;
  display: grid;
  grid-template: 1fr / 250px 1fr;
  grid-gap: 24px;
  overflow: scroll;

  .reward-item-details {
    height: calc(var(--content-inner-height) - 2 * var(--content-padding));
    padding: 24px;
    box-sizing: border-box;
    background-color: var(--White);
    border-radius: 8px;
    box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12),
      0 2px 16px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;

    .reward-media {
      height: 202px;
      width: 202px;
      margin-bottom: 24px;
      border-radius: 8px;
      background-color: var(--Gray200);
    }

    .detail-item {
      padding-top: 24px;
      width: 100%;

      .detail-item-title {
        font-size: 16px;
        font-weight: 600;
      }

      .detail-item-value {
        font-size: 20px;
      }
    }
  }
}
