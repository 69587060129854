.payout-transactions-table-container {
  --container-height: calc(var(--content-inner-height) - 2 * var(--content-padding));

  height: var(--container-height);
  display: flex;
  flex-direction: column;

  .payout-transaction-table-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .payout-transactions-table-title {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      height: calc(2 * var(--content-padding));
      font-size: 28px;
    }

    .payout-transactions-table-payout-date {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      height: calc(2 * var(--content-padding));
      font-size: 14px;
    }
  }

  .ka {
    --table-height: calc(var(--container-height) - 2 * var(--content-padding));
    --header-row-height: 48px;

    height: var(--table-height);

    .ka-tbody {
      max-height: calc(var(--table-height) - var(--header-row-height));
    }
  }

  .name-cell,
  .campaign-link {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .negative {
    color: var(--Red);
  }

  .campaign-link {
    color: var(--Blue);
  }

  .total-cell {
    font-weight: 600;
    letter-spacing: 0.5px;
  }
}
