.edit-tier-card {
  position: relative;
  height: 350px;
  width: 100%;
  margin-bottom: var(--content-padding);
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
  cursor: grab;
  user-select: none;

  .sorting-overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    color: var(--Gray700);
    font-size: 96px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 12px;
    overflow: hidden;
  }

  &.sorting {
    .sorting-overlay {
      display: flex;
      pointer-events: none;
    }
  }

  .tier-slides-container {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;

    > .tier-slide {
      position: absolute;
      height: 100%;
      width: 100%;
      padding: 12px 16px;
      box-sizing: border-box;
      top: 0;
      left: 0;
      transition: linear 150ms;
    }

    .tier-edit-container {
      &.active {
        transform: translateX(0%);
      }
      &.inactive {
        transform: translateX(-100%);
      }

      .top-bar {
        width: 100%;
        margin: 8px 0px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .total-claimed {
          color: var(--Gray500);
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.5px;
          text-transform: uppercase;
        }

        .avatars {
          display: flex;
          .avatar {
            height: 24px;
            width: 24px;
            margin-right: -8px;
            border: 2px solid #fff;
            border-radius: 24px;
            background-color: var(--Gray300);

            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }

      .title {
        color: var(--Gray900);
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 12px;
      }

      .description {
        margin-bottom: 16px;
        color: var(--Gray900);
        font-size: 14px;
        line-height: 1.71;
      }

      .donate-button {
        padding: 12px 0;
        margin-top: auto;
      }
    }

    .reward-edit-container {
      &.active {
        transform: translateX(0%);
      }

      &.inactive {
        transform: translateX(100%);
      }

      .top-bar {
        width: 100%;
        margin: 8px 0px 12px;

        .reward-edit-toggle {
          height: 18px;
          display: flex;
          align-items: center;
        }
      }

      .reward {
        margin-top: 24px;
      }

      .reserved-quantity {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        .reward-quantity-available {
          align-self: flex-end;
          margin-top: 4px;
          margin-bottom: 0;
          margin-right: 4px;
          color: var(--Gray500);
          font-size: 11px;
        }
      }
    }

    .reward-edit-toggle {
      color: var(--NickelPrimaryLight);
      font-size: 12px;
      cursor: pointer;
    }

    .edit-buttons-wrapper {
      display: flex;

      .media-toggle {
        margin-right: 12px;
      }
    }
  }

  textarea {
    min-height: 150px;
  }

  .delete-tier-button {
    position: absolute;
    z-index: 2;
    top: -16px;
    right: -16px;
    height: 32px;
    width: 32px;
    padding: 6px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: var(--Gray700);
    background-color: var(--White);
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
    display: none;
    cursor: pointer;

    > :hover {
      opacity: 0.7;
    }

    > :active {
      opacity: 0.3;
    }
  }

  &:hover,
  &:active {
    .delete-tier-button {
      display: flex;
    }
  }

  &.tier-drag-ghost {
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.32);
    pointer-events: auto !important;
    cursor: grabbing;

    .sorting-overlay {
      display: none;
    }
  }
}
