.social-slide {
  display: flex;
  flex-direction: column;

  .social-auth {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 36px;

    .social-provider {
      margin: 0px 24px;
      height: 150px;
      width: 125px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border: 1px solid #ddd;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
      cursor: pointer;

      .icon {
        color: black;

        &.instagram {
          color: #d6249f;
          // color: radial-gradient(
          //   circle at 30% 107%,
          //   #fdf497 0%,
          //   #fdf497 5%,
          //   #fd5949 45%,
          //   #d6249f 60%,
          //   #285aeb 90%
          // );
        }

        &.twitter {
          color: #1da1f2;
        }

        &.facebook {
          color: #3c5a99;
        }

        &.filled {
          color: white;
        }
      }
    }
    .social-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .social-tile {
        position: relative;
        margin: 0px 24px;
        height: 150px;
        width: 125px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: 1px solid #ddd;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
        cursor: pointer;

        .social-profile-picture {
          height: 75px;
          width: 75px;
          border-radius: 50px;
        }
      }
      .social-username {
        margin-top: 8px;
        color: var(--Gray500);
        max-width: 150px;
        text-overflow: ellipsis;
      }
      .social-delete {
        display: flex;
        position: absolute;
        justify-content: center;
        align-items: center;
        color: var(--Gray500);
        background-color: #fff;
        border: 1px solid #ddd;
        height: 24px;
        width: 24px;
        border-radius: 24px;
        top: -12px;
        right: -12px;
      }
    }
  }
}
