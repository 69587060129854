.bulletins-table-container {
  --container-height: calc(var(--content-inner-height) - 3 * var(--content-padding) - var(--summary-number-height));

  height: var(--container-height);
  display: flex;
  flex-direction: column;

  .ka {
    --header-row-height: 48px;

    .ka-tbody {
      max-height: calc(var(--container-height) - var(--header-row-height));
    }

    .cta-cell {
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      .campaign-link {
        color: var(--Blue);
      }
    }
  }
}
