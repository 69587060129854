.slide-buttons {
  position: absolute;
  bottom: 0;
  width: 581px;
  height: 68px;
  padding: 0px 16px;
  box-sizing: border-box;
  box-shadow: inset 0 1px 0 0 var(--Gray200);
  background-color: var(--White);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .right-aligned-container {
    display: flex;

    .next-button {
      margin-right: 16px;
    }
  }
}
