.audits-transactions {
  --summary-number-height: 144px;

  width: 100%;
  height: var(--content-inner-height);
  padding: var(--content-padding) var(--content-padding) 0px;
  overflow: scroll;
  box-sizing: border-box;

  .summary-numbers {
    width: 100%;
    margin-bottom: var(--content-padding);
    display: grid;
    grid-template: 1fr / repeat(5, 1fr);
    grid-gap: var(--content-padding);

    .summary-number {
      height: var(--summary-number-height);
      padding: 20px 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background-color: var(--White);
      border-radius: 8px;
      box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 16px 0 rgba(0, 0, 0, 0.08);

      &:nth-child(1) .icon-wrapper {
        background-color: var(--Purple);
      }

      &:nth-child(2) .icon-wrapper {
        background-color: var(--Green);
      }

      &:nth-child(3) .icon-wrapper {
        background-color: var(--Orange);
      }

      &:nth-child(4) .icon-wrapper {
        background-color: var(--Pink);
      }

      &:nth-child(5) .icon-wrapper {
        background-color: var(--Blue);
      }

      .icon-wrapper {
        --wrapper-size: 28px;
        height: var(--wrapper-size);
        width: var(--wrapper-size);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        .summary-icon {
          height: 16px;
          width: 16px;
          color: #fff;
        }
      }

      .value {
        font-size: 32px;
        font-weight: 500;
        letter-spacing: -0.5px;
      }

      .title {
        font-size: 12px;
        line-height: 16px;
      }

      .indicator {
        font-size: 10px;
        line-height: 12px;

        &.positive {
          color: var(--Green);
        }

        &.negative {
          color: var(--Red);
        }

        &.empty {
          color: var(--White);
          user-select: none;
        }
      }
    }
  }
}
