.payment-info-slide {
  .inputs {
    .double-input {
      width: 100%;
      display: flex;
      justify-content: space-between;

      > * {
        width: calc(50% - 8px);
      }
    }
  }
  .body-text {
    color: var(--Gray900);

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    a {
      color: var(--NickelPrimary);
    }
  }
}
