.info-slide {
  // gets positioning classes from .slide in NewCampaignModal.scss

  display: flex;
  flex-direction: column;

  .team-photo-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .team-icons-container {
    width: 100%;
    display: grid;
    grid-template-rows: 64px;
    grid-template-columns: repeat(auto-fit, minmax(48px, 1fr));
    grid-gap: 8px;

    .icon-square {
      height: 48px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.selected {
        border: 3px solid var(--primary-color);
        border-radius: 2px;
      }
      &:hover {
        opacity: 0.7;
      }
      &:active {
        opacity: 0.4;
      }
    }
  }

  .double-input-container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    > * {
      width: calc(50% - 8px);
    }
  }
}
