.team-members-slide {
  // gets positioning classes from .slide in NewCampaignModal.scss

  display: flex;
  flex-direction: column;

  .add-member {
    margin-top: 12px;
    font-size: 12px;
    color: var(--NickelPrimaryLight);
    cursor: pointer;
  }
  .team-member-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 16px !important;

    .input-fields {
      width: calc(50% - 30px);
    }

    .delete-team-member {
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Gray500);
      flex-shrink: 1;
      margin-left: 8px;
      cursor: pointer;
    }
  }

  .double-input-container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    > * {
      width: calc(50% - 8px);
    }
  }
}
